import React, { useEffect, useState } from 'react';
import dubleCheckMark from '../../asstes/dash-icons/dubleCheckMark.svg';
import { get_event_lists, update_event_by_id } from '../../../axiosCalls/userdashboard'; // Import API

const NotificationPopup = ({ handlerColseNotification }) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationHide, setNotificationHide] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const res = await get_event_lists();
        const eventData = res.data;

        const transformedNotifications = eventData
          .map(event => ({
            ...event,
            date: new Date(event.date), // Ensure date is an object
            read: event.read || false,  // Initialize read state
          }))
          .sort((a, b) => b.date - a.date); // Sort by date (descending)

        setNotifications(transformedNotifications);
      } catch (error) {
        console.error('Failed to fetch events:', error);
      }
    };

    fetchEvents();
  }, []);

  // Function to mark a single notification as read
  const handleNotificationClick = async (id) => {
    try {
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification.ID === id ? { ...notification, read: true } : notification
        )
      );

      const updateData = { read: true };
      const res = await update_event_by_id(id, updateData);
      if (res.status !== 200) {
        console.error('Failed to update notification status');
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  // Function to mark all notifications as read
  const handleMarkAllAsRead = async () => {
    try {
      // Update all notifications locally
      setNotifications(prevNotifications =>
        prevNotifications.map(notification => ({ ...notification, read: true }))
      );

      // Call backend for each notification to mark it as read
      const promises = notifications.map(notification =>
        update_event_by_id(notification.ID, { read: true })
      );

      // Wait for all requests to complete
      await Promise.all(promises);
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  };

  // Function to format the date
  const formatDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    if (date.toDateString() === today.toDateString()) return 'Today';
    if (date.toDateString() === yesterday.toDateString()) return 'Yesterday';
    if (date.toDateString() === tomorrow.toDateString()) return 'Tomorrow';

    return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Group notifications by formatted date
  const groupedNotifications = notifications.reduce((acc, notification) => {
    const formattedDate = formatDate(notification.date);
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(notification);
    return acc;
  }, {});

  const handleNotificationClose = () => {
    setNotificationHide(true);
    handlerColseNotification(false);
  };

  // Utility function to truncate the description to a specific word count
  const truncateDescription = (text, wordLimit = 15) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) return text;

    return words.slice(0, wordLimit).join(' ') + '...';
  };

  return (
    <>
      {!notificationHide && (
        <>
          <div className="sidenav-overlay" onClick={handleNotificationClose} style={{ left: '0px' }}></div>
          <div className="notification-popup">
            <div className="notification-close" onClick={handleNotificationClose}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.0612 18.9387C21.343 19.2205 21.5013 19.6027 21.5013 20.0012C21.5013 20.3997 21.343 20.7819 21.0612 21.0637C20.7794 21.3455 20.3972 21.5038 19.9987 21.5038C19.6002 21.5038 19.218 21.3455 18.9362 21.0637L10.9999 13.125L3.0612 21.0612C2.7794 21.343 2.39721 21.5013 1.9987 21.5013C1.60018 21.5013 1.21799 21.343 0.936196 21.0612C0.654403 20.7794 0.496094 20.3972 0.496094 19.9987C0.496094 19.6002 0.654403 19.218 0.936196 18.9362L8.87495 11L0.938695 3.06122C0.656903 2.77943 0.498594 2.39724 0.498594 1.99872C0.498594 1.60021 0.656903 1.21802 0.938695 0.936225C1.22049 0.654432 1.60268 0.496123 2.0012 0.496123C2.39971 0.496123 2.7819 0.654432 3.0637 0.936225L10.9999 8.87498L18.9387 0.934975C19.2205 0.653182 19.6027 0.494873 20.0012 0.494873C20.3997 0.494873 20.7819 0.653182 21.0637 0.934975C21.3455 1.21677 21.5038 1.59896 21.5038 1.99747C21.5038 2.39599 21.3455 2.77818 21.0637 3.05998L13.1249 11L21.0612 18.9387Z" fill="#00487C" />
              </svg>
            </div>
            <div className="notification-header">
              <div className="notification-title">Notifications</div>
              <div className="notification-button" onClick={handleMarkAllAsRead}>
                <img src={dubleCheckMark} alt="Mark all as read" />Mark all as read
              </div>
            </div>
            <div className="notification-list mt-3">
              {Object.entries(groupedNotifications).map(([date, notifications]) => (
                <div key={date}>
                  <div className="notifyItem-date">{date}</div>
                  {notifications.map((notification) => (
                    <div
                      className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                      key={notification.ID}
                      onClick={() => handleNotificationClick(notification.ID)}
                    >
                      <div className="notifyItem-title">
                        <svg width="5" height="6" viewBox="0 0 5 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="2.5" cy="3" r="2.5" fill={notification.read ? "#4CAF50" : "#BE6060"} />
                        </svg>
                        {notification.title}
                      </div>
                      <div className="notifyItem-content">{truncateDescription(notification.description)}</div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NotificationPopup;

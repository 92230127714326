import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import pdfIcon from "../../asstes/dash-icons/pdfIcon.svg";
import LinkReminders from "./linkReminders";
import { get_home_essential_types } from "../../../axiosCalls/userdashboard";
import axios from "axios"; // Import axios for API calls
import FormData from "form-data"; // Import FormData for file uploads

const HomeEssentialsSideNav = ({ formData, updateFormData }) => {
  const {
    homeEssentialName,
    make,
    modelNumber,
    purchaseDate,
    ageofEssential,
    notes,
    warrantyCheck,
    selectedType,
    warrantyData,
    files,
    warrantyFiles,
    images,
    linkedReminders,
  } = formData;

  const [startDate, setStartDate] = useState(new Date());
  const [homeEssentialTypes, setHomeEssentialTypes] = useState([]);
  const [listOfFiles, setlistOfFiles] = useState([]); // Unified list for files and images
  const [listOfImages, setlistOfImages] = useState([]); // Unified list for files and images

  // Callback function to get reminders from the child component
  const handleRemindersUpdate = (reminders) => {
    updateFormData({ linkedReminders: reminders });
  };

  const removeFile = (index) => {
    // Remove the item by index from the list
    const updatedList = listOfFiles.filter((_, i) => i !== index);
    setlistOfFiles(updatedList);
    // Update the parent formData
    const updatedImages = updatedList.filter(
      (item) => item.type !== "application/pdf"
    );
    const updatedFiles = updatedList.filter(
      (item) => item.type === "application/pdf"
    );
    updateFormData({ images: updatedImages, files: updatedFiles });
  };
  const removeImage = (index) => {
    // Remove the item by index from the list
    const updatedList = listOfImages.filter((_, i) => i !== index);
    setlistOfImages(updatedList);
    // Update the parent formData
    const updatedImages = updatedList.filter(
      (item) => item.type !== "application/pdf"
    );
    const updatedFiles = updatedList.filter(
      (item) => item.type === "application/pdf"
    );
    updateFormData({ images: updatedImages, files: updatedFiles });
  };

  const fetch_home_essential_types = async () => {
    try {
      const response = await get_home_essential_types();
      setHomeEssentialTypes(response.data);
    } catch (error) {
      console.error("Error fetching home essential types:", error);
    }
  };

  useEffect(() => {
    fetch_home_essential_types();
  }, []);

  const handlerWarrantyChecked = () => {
    updateFormData({
      warrantyCheck: warrantyCheck === "unchecked" ? "checked" : "unchecked",
    });
  };

  // Handle file input changes for files
  const handleFileChange = (e) => {
    const selectedFiles = [...e.target.files];
    const updatedList = [...listOfFiles, ...selectedFiles];
    setlistOfFiles(updatedList);

    updateFormData({ files: selectedFiles });
  };

  // Handle file input changes for warranty files
  const handleWarrantyFileChange = (e) => {
    const selectedWarrantyFiles = [...e.target.files];
    updateFormData({ warrantyFiles: selectedWarrantyFiles });
  };

  // Handle image input changes
  const handleImageChange = (e) => {
    const selectedImages = [...e.target.files];
    const updatedList = [...listOfImages, ...selectedImages];
    setlistOfImages(updatedList);
    updateFormData({ images: selectedImages });
  };

  return (
    <div className="side-nav">
      <div className="side-header">
        <h3>Add Home Essential</h3>
      </div>
      <div className="side-body">
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>Home Essential Name </Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={homeEssentialName}
                onChange={(e) =>
                  updateFormData({ homeEssentialName: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Type <span className="requiredStar">*</span> </Form.Label>
              <Form.Select
                value={selectedType}
                onChange={(e) =>
                  updateFormData({ selectedType: e.target.value })
                }
              >
                <option value="">Select Type</option>
                {homeEssentialTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>Make </Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. 2012"
                value={make}
                onChange={(e) => updateFormData({ make: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Label>Model Number </Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. 4689-65498-4646"
                value={modelNumber}
                onChange={(e) =>
                  updateFormData({ modelNumber: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Label>
                Purchase Date <span className="requiredStar">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="date"
                value={purchaseDate}
                onChange={(e) =>
                  updateFormData({ purchaseDate: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Label>Age of Home Essential </Form.Label>
              <Form.Control
                type="date"
                placeholder="Name"
                value={ageofEssential}
                onChange={(e) =>
                  updateFormData({ ageofEssential: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group md="12" className="mb-3">
              <div className="link-table-main">
                <Form.Label>Linked Reminders</Form.Label>
                <LinkReminders
                  type="homeEssential"
                  onRemindersUpdate={handleRemindersUpdate}
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group md="12" className="mb-3">
              <div className="files-choosen">
                <div className="title">Files </div>
                <div className="button">
                  <div className="outline-yellow-button">
                    Add file
                    <input
                      type="file"
                      className="chooeseFile"
                      accept=".pdf"
                      multiple
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              {/* Display files and images */}
              <div className="listOfImages">
                {listOfFiles.map((file, index) => (
                  <div className="image-choosed" key={index}>
                    {file.type === "application/pdf" ? (
                      <img src={pdfIcon} alt="PDF Icon" />
                    ) : (
                      <img src={URL.createObjectURL(file)} alt="Selected" />
                    )}
                    <button
                      onClick={() => removeFile(index)}
                      className="image-choosed-crossed"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </Form.Group>
            <Form.Group md="12" className="mb-3">
              <div className="files-choosen">
                <div className="title">Images </div>
                <div className="button">
                  <div className="outline-yellow-button">
                    Add image
                    <input
                      type="file"
                      className="chooeseFile"
                      accept=".png, .jpeg, .jpg, .webp"
                      multiple
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
              </div>
              {/* Display files and images */}
              <div className="listOfImages">
                {listOfImages.map((file, index) => (
                  <div className="image-choosed" key={index}>
                    {file.type === "application/pdf" ? (
                      <img src={pdfIcon} alt="PDF Icon" />
                    ) : (
                      <img src={URL.createObjectURL(file)} alt="Selected" />
                    )}
                    <button
                      onClick={() => removeImage(index)}
                      className="image-choosed-crossed"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </Form.Group>

            <Form.Group as={Col} className="mb-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Type here..."
                value={notes}
                onChange={(e) => updateFormData({ notes: e.target.value })}
              />
            </Form.Group>

            <Form.Group md="12" className="mb-3">
              <div className="files-choosen warrantyAddArea">
                <div className="title">Warranty</div>
                <div className="button">
                  <label className="switch">
                    <input type="checkbox" onChange={handlerWarrantyChecked} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="warrantyForm">
                {warrantyCheck === "checked" && (
                  <div className="warrantyAddInnerArea">
                    <Row className="mb-3">
                      <Form.Group as={Col} md="12" className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={warrantyData.name}
                          onChange={(e) =>
                            updateFormData({
                              warrantyData: {
                                ...warrantyData,
                                name: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="12" className="mb-3">
                        <Form.Label>Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="number"
                          placeholder="Number"
                          value={warrantyData.number}
                          onChange={(e) =>
                            updateFormData({
                              warrantyData: {
                                ...warrantyData,
                                number: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group md="12" className="mb-3">
                        <div className="files-choosen">
                          <div className="title">Files</div>

                          <div className="button">
                            <div className="outline-yellow-button">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                                  stroke="#B78C4E"
                                  strokeLinecap="round"
                                />
                              </svg>
                              Add file
                              <input
                                type="file"
                                className="chooeseFile"
                                accept=".png, .jpeg, .jpg, .webp, .pdf"
                                multiple
                                onChange={handleWarrantyFileChange}
                              />
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>Start </Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={warrantyData.startDate}
                          onChange={(e) =>
                            updateFormData({
                              warrantyData: {
                                ...warrantyData,
                                startDate: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="6" className="mb-3">
                        <Form.Label>End</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={warrantyData.endDate}
                          onChange={(e) =>
                            updateFormData({
                              warrantyData: {
                                ...warrantyData,
                                endDate: e.target.value,
                              },
                            })
                          }
                        />
                      </Form.Group>
                    </Row>
                    <Form.Group as={Col} className="mb-3">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="notes"
                        placeholder="Type here..."
                        value={warrantyData.notes}
                        onChange={(e) =>
                          updateFormData({
                            warrantyData: {
                              ...warrantyData,
                              notes: e.target.value,
                            },
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                )}
              </div>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default HomeEssentialsSideNav;

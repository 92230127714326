import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import plusIcon from "../../asstes/dash-icons/plusIcon.svg";
import { getSuggestedReminders } from "../../../axiosCalls/userdashboard";
import checkedIcon from "../../asstes/dash-icons/checkedIcon.svg"; // Add a checked icon for selection
import pdfIcon from "../../asstes/dash-icons/pdfIcon.svg";

const FilesSideNavAdd = ({ handlerCustomReminder }) => {
  const [formData, setFormData] = useState({
    fileName: "",
    uploadDate: "",
    files: [], // State for uploaded files
    reminderIds: [], // State for storing selected reminder IDs
  });
  const [suggestedReminders, setSuggestedReminders] = useState([]);
  const [selectedReminders, setSelectedReminders] = useState([]);

  useEffect(() => {
    const fetchReminders = async () => {
      try {
        const reminders = await getSuggestedReminders();
        setSuggestedReminders(reminders.data);
      } catch (error) {
        console.error("Error fetching reminders:", error);
      }
    };

    fetchReminders();
  }, []);

  const handleReminderClick = (id) => {
    setSelectedReminders((prevSelected) => {
      const newSelected = prevSelected.includes(id)
        ? prevSelected.filter((reminderId) => reminderId !== id) // Remove if already selected
        : [...prevSelected, id]; // Add if not selected

      // Update reminderIds in formData
      setFormData((prevData) => ({
        ...prevData,
        reminderIds: newSelected, // Update the reminderIds array in the formData
      }));

      return newSelected;
    });
  };

  // Handle file selection
  const handleFileChange = (e, type) => {
    const selectedFiles = Array.from(e.target.files);
    setFormData((prevData) => ({
      ...prevData,
      [type]: [...prevData[type], ...selectedFiles],
    }));
  };

  // Function to get the appropriate icon for files
  const getFileIcon = (file) => {
    if (file.type === "application/pdf") {
      return pdfIcon; // PDF icon
    } else {
      return URL.createObjectURL(file); // Image preview
    }
  };

  // Remove file from the list
  const removeFile = (index, type) => {
    const updatedFiles = formData[type].filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      [type]: updatedFiles,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Update the specific field in the state object
    }));
  };

  useEffect(() => {
    // Automatically pass formData with selected reminderIds and other data to the parent handler
    handlerCustomReminder(formData);
  }, [formData, handlerCustomReminder]);

  return (
    <div className="side-nav">
      <div className="side-header">
        <h3>Add File</h3>
      </div>
      <div className="side-body">
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                File Name <span className="requiredStar">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="File name"
                name="fileName" // Use name attribute to differentiate fields
                value={formData.fileName}
                onChange={handleChange} // Update state on input change
                required
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="mb-3">
              <Form.Label>
                Upload Date <span className="requiredStar">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="uploadDate" // Use name attribute to differentiate fields
                value={formData.uploadDate}
                onChange={handleChange} // Update state on date change
                required
              />
            </Form.Group>
            <Form.Group md="12" className="mb-3">
              <div className="files-choosen uploadFile">
                <div className="title">
                  You can attach different formats: png, pdf, jpg
                </div>
                <div className="button">
                  <div className="outline-button-upload">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 7.50015V11.0001C12 11.2654 11.8946 11.5197 11.7071 11.7073C11.5196 11.8948 11.2652 12.0001 11 12.0001H1C0.734784 12.0001 0.48043 11.8948 0.292893 11.7073C0.105357 11.5197 0 11.2654 0 11.0001V7.50015C0 7.36754 0.0526785 7.24036 0.146447 7.1466C0.240215 7.05283 0.367392 7.00015 0.5 7.00015C0.632608 7.00015 0.759785 7.05283 0.853553 7.1466C0.947321 7.24036 1 7.36754 1 7.50015V11.0001H11V7.50015C11 7.36754 11.0527 7.24036 11.1464 7.1466C11.2402 7.05283 11.3674 7.00015 11.5 7.00015C11.6326 7.00015 11.7598 7.05283 11.8536 7.1466C11.9473 7.24036 12 7.36754 12 7.50015ZM3.85375 3.3539L5.5 1.70702V7.50015C5.5 7.63276 5.55268 7.75993 5.64645 7.8537C5.74021 7.94747 5.86739 8.00015 6 8.00015C6.13261 8.00015 6.25979 7.94747 6.35355 7.8537C6.44732 7.75993 6.5 7.63276 6.5 7.50015V1.70702L8.14625 3.3539C8.24007 3.44772 8.36732 3.50043 8.5 3.50043C8.63268 3.50043 8.75993 3.44772 8.85375 3.3539C8.94757 3.26008 9.00028 3.13283 9.00028 3.00015C9.00028 2.86747 8.94757 2.74022 8.85375 2.6464L6.35375 0.146399C6.30731 0.0999109 6.25217 0.0630315 6.19147 0.0378693C6.13077 0.0127072 6.06571 -0.000244141 6 -0.000244141C5.93429 -0.000244141 5.86923 0.0127072 5.80853 0.0378693C5.74783 0.0630315 5.69269 0.0999109 5.64625 0.146399L3.14625 2.6464C3.05243 2.74022 2.99972 2.86747 2.99972 3.00015C2.99972 3.13283 3.05243 3.26008 3.14625 3.3539C3.24007 3.44772 3.36732 3.50043 3.5 3.50043C3.63268 3.50043 3.75993 3.44772 3.85375 3.3539Z"
                        fill="#00487C"
                      />
                    </svg>
                    Upload file
                    <input
                      type="file"
                      className="chooeseFile"
                      multiple
                      accept=".png, .pdf, .jpg"
                      onChange={(e) => handleFileChange(e, "files")}
                    />
                  </div>
                </div>
              </div>
              <div className="listOfFiles">
                {formData.files.map((file, index) => (
                  <div className="image-choosed" key={index}>
                    <a
                      href={URL.createObjectURL(file)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={getFileIcon(file)} alt="File preview" />
                    </a>
                    <button
                      onClick={() => removeFile(index, "files")}
                      type="button"
                      className="image-choosed-crossed"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </Form.Group>
          </Row>

          <div className="mb-3">
            <h1 className="side-nav-main-heading">Suggested reminders</h1>
          </div>

          {suggestedReminders.map((item) => (
            <div
              className="suggetes-item"
              key={item.id}
              onClick={() => handleReminderClick(item.id)}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="titleSuggestItem">{item.title}</div>
              <div>
                <img
                  src={
                    selectedReminders.includes(item.id) ? checkedIcon : plusIcon
                  }
                  alt="icon"
                />
              </div>
            </div>
          ))}
        </Form>
      </div>
    </div>
  );
};

export default FilesSideNavAdd;

import React, { useEffect, useState } from "react";

import icon1 from "../asstes/dash-icons/sidebar/dashIcon1.svg";
import infoIcon from "../asstes/dash-icons/info.svg";
import warningIcon from "../asstes/dash-icons/warningIcon.png";
import HomeEssentialsSideNav from "../components/inc/homeEssentialsSideNav";
import HomeEssentialsSideNavView from "../components/inc/homeEssentialsSideNavView";
import HomeEssentialsSideNavEdit from "../components/inc/homeEssentialsSideNavEdit";
import axios from "axios";
import {
  addHomeEssential,
  getHomeEsstentialList,
  updateHomeEssential,
} from "../../axiosCalls/userdashboard";
import { toast } from "react-toastify";
import { isPropertyExist } from "../utils/helper";
import { driver } from "driver.js";
import AddHomeEssential from "../components/add_home_essential/addHomeEssential";
import QuickAction from "../../quick-action/QuickAction";

const HomeEssentials = () => {
  const homeEssentialDriver = driver({
    showProgress: "true",
    steps: [
      {
        element: "#add-home-essential",
        popover: {
          title: "Add Home Essential",
          description:
            "Click button and Pick a category and fill out the form to save the data about essential and add this to your dashboard.",
        },
      },
    ],
  });
  const [sideNavWidth, setSideNavWidth] = useState("0px");
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState("0%");
  const [continueChange, setContinueChange] = useState("createStep");
  const [valueForView, setValueForView] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  const [formData, setFormData] = useState({
    homeEssentialName: "",
    make: "",
    modelNumber: "",
    purchaseDate: "",
    files: [],
    warrantyFiles: [],
    images: [],
    notes: "",
    warrantyData: {
      name: "",
      number: "",
      startDate: "",
      endDate: "",
      notes: "",
      files: [],
    },
    linkedReminders: [],
    selectedType: "",
    warrantyCheck: "unchecked",
  });
  // Handler to update the form data from the child component
  const updateFormData = (updatedData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };
  const updatedFormData = (data) => {
    setUpdatedData(data);
  };

  const handleUpdateHomeEssential = async () => {
    const {
      name,
      model_number,
      make,
      purchase_date,
      age_of_home_essential,
      linked_reminders,
      notes,
      type,
      type_id,
      warranty,
      warranty_data,
      files,
      images,
      warrantyFiles,
    } = updatedData;
    // Validate required fields
    if (!type_id) {
      toast.error("Please select a Home Essential Type");
      return;
    }

    let data = new FormData();
    data.append("type", type_id);
    if (name) data.append("name", name);
    if (make) data.append("make", make);
    if (model_number) data.append("model_number", model_number);
    if (age_of_home_essential)
      data.append("age_of_home_essential", age_of_home_essential);
    data.append("purchase_date", purchase_date);
    linked_reminders.forEach((remind) => {
      const reminderData = JSON.stringify({
        id: remind.id,
        date: remind.start_date,
      });
      data.append("linked_reminders[]", reminderData);
    });
    if (notes) data.append("notes", notes);
    if (warranty) data.append("warranty", warranty);

    if (warranty) {
      if (warranty_data[0].name)
        data.append("warranty_data[][name]", warranty_data[0].name);
      if (warranty_data[1].number)
        data.append("warranty_data[][number]", warranty_data[1].number);
      if (warranty_data[2].start_date)
        data.append("warranty_data[][start_date]", warranty_data[2].start_date);
      if (warranty_data[3].end_date)
        data.append("warranty_data[][end_date]", warranty_data[3].end_date);
      if (warranty_data[4].notes)
        data.append("warranty_data[][notes]", warranty_data[4].notes);
      if (warranty_data.files)
        data.append("warranty_data[files]", warranty_data.files);
      if (warrantyFiles) {
        warrantyFiles.forEach((file) =>
          data.append("warranty_data_files[]", file)
        );
      }
    }
    if (files?.length) files.forEach((file) => data.append("files[]", file));
    if (images?.length)
      images.forEach((image) => data.append("images[]", image));
    if (warranty_data.files?.length)
      warranty_data.files.forEach((image) =>
        data.append("warranty_data[files][]", image)
      );

    try {
      const response = await updateHomeEssential(data, updatedData.id);
      if (response.status == 200) {
        toast.success(response.data.message);
        handlerCancelSideNav();
      }
      // Optionally, trigger an update to the parent component or redirect
    } catch (error) {
      console.error("Error updating home essential:", error);
    }
  };
  // Function to handle the submit from parent
  const handleSubmitHomeEssentials = async () => {
    const {
      homeEssentialName,
      make,
      modelNumber,
      purchaseDate,
      ageofEssential,
      linkedReminders,
      notes,
      selectedType,
      warrantyCheck,
      warrantyData,
      files,
      images,
      warrantyFiles,
    } = formData;
    // Validate required fields
    if (!selectedType) {
      toast.error("Please select a Home Essential Type");
      return;
    }
    if (!purchaseDate) {
      toast.error("Purchase Date is required");
      return;
    }
    let data = new FormData();
    data.append("type", selectedType);
    if (homeEssentialName) data.append("name", homeEssentialName);
    if (make) data.append("make", make);
    if (modelNumber) data.append("model_number", modelNumber);
    if (ageofEssential) data.append("age_of_home_essential", ageofEssential);
    data.append("purchase_date", purchaseDate);
    linkedReminders.forEach((remind) => {
      const reminderData = JSON.stringify({
        id: remind.id,
        date: remind.start_date,
      });
      data.append("linked_reminders[]", reminderData);
    });
    if (notes) data.append("notes", notes);
    data.append("warranty", warrantyCheck === "checked" ? "true" : "false");

    if (warrantyCheck === "checked") {
      if (warrantyData.name)
        data.append("warranty_data[][name]", warrantyData.name);
      if (warrantyData.number)
        data.append("warranty_data[][number]", warrantyData.number);
      if (warrantyData.startDate)
        data.append("warranty_data[][start_date]", warrantyData.startDate);
      if (warrantyData.endDate)
        data.append("warranty_data[][end_date]", warrantyData.endDate);
      if (warrantyData.notes)
        data.append("warranty_data[][notes]", warrantyData.notes);
      if (warrantyFiles.length)
        warrantyFiles.forEach((file) =>
          data.append("warranty_data_files[]", file)
        );
    }
    if (files.length) files.forEach((file) => data.append("files[]", file));
    if (images.length)
      images.forEach((image) => data.append("images[]", image));

    try {
      const response = await addHomeEssential(data);
      if (response.data.post_id) {
        toast.success(response.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.error("Error submitting home essential:", error);
    }
  };
  const handlerReminderSideNave = () => {
    if (isPropertyExist()) {
      setContinueChange("createStep");
      setSideNavWidth("sidenavPopuWidth");
      setsideNavWidthOverlay("100%");
      document.body.classList.add("no-scroll");
    }
  };
  const handlerCancelSideNav = () => {
    setSideNavWidth("sidenavPopuWidthHide");
    setsideNavWidthOverlay("0%");
    document.body.classList.remove("no-scroll");
  };
  const handlerChangeStep = (e) => {
    if (continueChange === "suggets") {
      setContinueChange("EditStep");
    } else if (continueChange === "createStep") {
      handleSubmitHomeEssentials();
    } else {
      handleUpdateHomeEssential();
    }
  };
  const handlerBackSideNav = () => {
    if (continueChange === "createStep") {
      setContinueChange("suggets");
    } else {
      setContinueChange("suggets");
    }
  };

  const [homeEssentials, setHomeEssentials] = useState([]);

  // Fetch home essentials when the component is mounted
  useEffect(() => {
    const fetchHomeEssentials = async () => {
      try {
        const response = await getHomeEsstentialList();
        if (response && response.data && response.data.items) {
          setHomeEssentials(response.data.items);
        } else {
          console.error("No home essential data found");
        }
      } catch (error) {
        console.error("Error fetching home essentials:", error);
      }
    };

    fetchHomeEssentials();
  }, []);

  function HandlerViewHomeEsstional(id, postID) {
    setValueForView(postID);
    setContinueChange("suggets");

    setSideNavWidth("sidenavPopuWidth");
    setsideNavWidthOverlay("100%");
    document.body.classList.add("no-scroll");
  }
  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-xl-12 grid-margin stretch-card flex-column">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 d-flex align-items-center">
              <h5 className="mb-2 text-titlecase mb-4">Home Essentials</h5>
              <img
                onClick={() => {
                  homeEssentialDriver.destroy();

                  homeEssentialDriver.drive();
                }}
                src={infoIcon}
                width={"14px"}
                className="mb-4 pb-2 ms-2 pointer"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
              <div className=" top-text-head">
                <div className="reminder-top-button">
                  <button
                    className="filled-btn"
                    id="add-home-essential"
                    onClick={handlerReminderSideNave}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00065 3.33594V12.6693M3.33398 8.0026H12.6673"
                        stroke="white"
                        strokeLinecap="round"
                      />
                    </svg>
                    Add home essential
                  </button>
                </div>
              </div>
            </div>
            <div className="row paddingRight0">
              {!homeEssentials.length ? (
                <AddHomeEssential
                  heading={"Add home essential"}
                  onClick={handlerReminderSideNave}
                />
              ) : null}
              {homeEssentials.map((item, index) => (
                <div
                  className="col-lg-3 col-md-6 col-sm-6 item_each_home_cards"
                  id={index}
                  key={item.id} // Use item.id as the key for unique identification
                  onClick={() => HandlerViewHomeEsstional(index, item.id)}
                >
                  <div className="dashboard-card">
                    <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                      <div className="justify-content-between align-items-center">
                        <div className="icon-box-card">
                          {/* Display the first image from the images array */}

                          <img src={`${icon1}`} alt={item.model_number} />

                          {item.warranty && (
                            <div className="card-warining">
                              <img alt="Warning" src={warningIcon} />
                              <span>Warning</span>
                            </div>
                          )}
                        </div>
                        <p className="dashboard-heading">{item.name}</p>
                        <p className="dashboard-subheading">{item.type}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={handlerCancelSideNav}
        style={{ width: sideNavWidthOverlay }}
        className="sidenav-overlay"
      ></div>
      <QuickAction sidebarInfoFunc={handlerReminderSideNave} />
      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
        {continueChange === "suggets" ? (
          <HomeEssentialsSideNavView propsValue={valueForView} />
        ) : continueChange === "createStep" ? (
          <HomeEssentialsSideNav
            formData={formData}
            updateFormData={updateFormData}
          />
        ) : (
          <HomeEssentialsSideNavEdit
            homeEssentialId={valueForView}
            updatedFormData={updatedFormData}
          />
        )}

        <div className="side-footer">
          <button
            className="footer-button-cancel"
            onClick={handlerCancelSideNav}
          >
            Cancel
          </button>

          <button
            className="footer-button-primary "
            onClick={handlerChangeStep}
          >
            {continueChange !== "suggets" ? "Save" : "Edit"}
          </button>
          {continueChange !== "createStep" && continueChange !== "suggets" ? (
            <button className="footer-button-delete footer-button-back">
              Delete
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeEssentials;

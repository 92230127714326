import ApiCalendar from "react-google-calendar-api";

const config = {
  clientId: "556810777101-dqkh0jjkiaplh2ugqnled0dhbg0s0udu.apps.googleusercontent.com",
  apiKey: "AIzaSyDnte6bMKD8f7QtPZuBkPY7_r24p96CMZU",
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const apiCalendar = new ApiCalendar(config);
export default apiCalendar;

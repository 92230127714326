import React, { useState, useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import { getReminderById } from "../../../axiosCalls/userdashboard";

const FilesSideNavView = ({ selectValue }) => {
    const [data, setData] = useState([]);
    const [files, setFiles] = useState([]);
    const [reminderIds, setReminderIds] = useState([]);
    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);

    useEffect(() => {
        setData(selectValue); // Ensure state updates when selectValue changes
     }, [selectValue]);

     useEffect(() => {
        if (data) {
            setFiles(data.files || []);
            fetchReminders(data.reminder_ids || []);
        }
    }, [data]);

    const fetchReminders = async (reminderIds) => {
        try {
            const reminders = await Promise.all(
                reminderIds.map(remind => getReminderById(remind))
            );
            setReminderIds(reminders.map(reminder => reminder.data)); // Adjust based on your response structure
        } catch (error) {
            console.error("Error fetching reminders:", error);
            // Handle error as needed (e.g., show a notification)
        }
    };

    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };

    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    const getFileIcon = (file) => {
        if (typeof file === 'string') {
            const fileExtension = file.split('.').pop().toLowerCase();
            return fileExtension === 'pdf' ? pdfIcon : file;
        }
        return pdfIcon; // Default icon for unsupported file types
    };

    return (
        <>
        {data ?
            <div className="side-nav">
                <div className="side-header">
                    <h3>Files</h3>
                </div>
                <div className="side-body homeEssentialSideNav">
                    <Form>
                        <Row>
                            {/* File Name */}
                            <Form.Group as={Col} md="6" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Name</Form.Label>
                                    <p className="text-paragraph">{data ? data.file_name : "N/A"}</p>
                                </div>
                            </Form.Group>

                            {/* Upload Date */}
                            <Form.Group as={Col} md="6" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Upload Date</Form.Label>
                                    <p className="text-paragraph">
                                        {data ? new Date(data.upload_date).toLocaleDateString() : "N/A"}
                                    </p>
                                </div>
                            </Form.Group>

                            {/* Attached Files */}
                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Attached Files</Form.Label>
                                    <div className="listofFiles">
                                        {files.length > 0 ? (
                                            files.map((file, index) => (
                                                <div className="warrantyFile" key={index}>
                                                    <div className="text">
                                                        <img src={getFileIcon(file)} className='fileItemImage' alt="fileIcon" />
                                                        {file.split("/").pop()}
                                                    </div>
                                                    <div className="actions">
                                                        <span onClick={() => handleViewFile(file)} style={{ cursor: "pointer" }}>
                                                            <img src={eyeIcon} alt="View" />
                                                        </span>
                                                        <span onClick={() => handleDownloadFile(file)} style={{ cursor: "pointer" }}>
                                                            <img src={downloadIcon} alt="Download" />
                                                        </span>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No files available.</p>
                                        )}
                                    </div>
                                </div>
                            </Form.Group>

                            {/* Suggested Reminders */}
                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Suggested Reminders</Form.Label>
                                    {reminderIds.length > 0 ? (
                                        reminderIds.map((reminder, index) => (
                                            <p key={index} className="text-paragraph">
                                                {reminder.title} {/* Adjust based on your reminder structure */}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No reminders available.</p>
                                    )}
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                {/* Modal to View File */}
                <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {fileToView && fileToView.endsWith('.pdf') ? (
                            <iframe
                                src={fileToView}
                                style={{ width: '100%', height: '500px' }}
                                frameBorder="0"
                                title="PDF Viewer"
                            />
                        ) : (
                            <img src={fileToView} alt="File" style={{ width: '100%' }} />
                        )}
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </div>:''}
        </>
    );
};

export default FilesSideNavView;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dashboardMenuItem from "../inc/menu-items";
import commandImg from "../../asstes/dash-icons/sidebar/Command.svg";
import menuPlusIcon from "../../asstes/dash-icons/menuPlusIcon.svg";
import CaretUpDown from "../../asstes/dash-icons/CaretUpDown.svg";
import "../../asstes/styles/mainSidebarStyles.css";
import SearchModel from "../inc/searchModel";
import NotificationPopup from "../inc/notification-popup";
import propertyImg from "../../asstes/images/propertyImg.jpeg";
import homeIcon from "../../asstes/dash-icons/homeIcon.svg";
import { ReactComponent as DelIcon } from "../../asstes/dash-icons/deleteIcon.svg";
import {
  addPropertyData,
  deleteProperties,
  get_event_lists,
  getProperties,
} from "../../../axiosCalls/userdashboard";
import PropertyNewSideNav from "../inc/propertyNewSideNav";
import { toast } from "react-toastify";
import { isPlanActive } from "../../utils/helper";
import ConfirmationModal from "../inc/confirmationModa";

const MainSidebar = ({ stateSide, handlerCloseSidebarFromClick }) => {
  const navigate = useNavigate(); // Initialize navigate function

  const [propertyData, setPropertyData] = useState({}); // Initialize with any default data if needed
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState("0%");
  const [sideNavWidth, setsideNavWidth] = useState(stateSide);
  const [activeItem, setActiveItem] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [properties, setProperties] = useState([]); // State to store properties
  const [showPropertyList, setShowPropertyList] = useState(false); // Toggle for property list
  const [notifications, setNotifications] = useState([]);
  const [delConfrm, setDelConfrm] = useState(false);
  const [selectForDelProp, setSelectForDelProp] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(() => {
    return JSON.parse(localStorage.getItem("selectedProperty")) || null;
  }); // Load from localStorage
  const [valueForAdd, setValueForAdd] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const isPlnActive = isPlanActive();
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const res = await get_event_lists();
        const eventData = res.data;
        const unreadEvents = eventData.filter((event) => event.read === null);
        setNotifications(unreadEvents);
      } catch (error) {
        console.error("Failed to fetch events:", error);
      }
    };

    fetchEvents();
  }, []);

  const closeDelConfrmModal = () => {
    setDelConfrm(false);
    setSelectForDelProp(null);
  };
  const handlerCancelSideNav = () => {
    setsideNavWidthOverlay("0%");
    setsideNavWidth("close");
  };

  useEffect(() => {
    handlerFetchProperty();

    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === "f") {
        event.preventDefault();
        setModalShow(true);
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handlerFetchProperty = async () => {
    try {
      const response = await getProperties();
      if (response && response.data) {
        setProperties(response.data); // Update state with fetched properties
        const fetchedProperties = response.data;

        // If no property is selected, set the first property as the default
        if (!selectedProperty && fetchedProperties.length > 0) {
          const firstProperty = fetchedProperties[0];
          setSelectedProperty(firstProperty);
          localStorage.setItem(
            "selectedProperty",
            JSON.stringify(firstProperty)
          );
        }
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const handlePropertyClick = () => {
    if (!isPlnActive) {
      setShowPropertyList(!showPropertyList); // Toggle the list visibility
    }
  };

  const handleSelectProperty = (property) => {
    setSelectedProperty(property); // Update the selected property
    localStorage.setItem("selectedProperty", JSON.stringify(property)); // Store in localStorage
    setShowPropertyList(false); // Hide the list after selection
    window.location.reload();
  };

  const handleItemClick = (id) => {
    if (id === "logout") {
      // Clear the authentication token or any other user data
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedProperty");

      // Redirect to the login page or another appropriate page
      navigate("/login");
    } else {
      setActiveItem(id);
    }

    handlerCloseSidebarFromClick("close");

    setsideNavWidth("close");
    document.body.classList.remove("no-scroll");

    if (id === "notification") {
      setNotificationPopup(!notificationPopup);
    } else {
      setNotificationPopup(false);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlerColseNotification = (val) => {
    setNotificationPopup(val);
  };

  const handleOpenSideNavPropertyAdd = () => {
    if (!isPlnActive) {
      setsideNavWidthOverlay("100%");
      setsideNavWidth("sidenavPopuWidth");
      document.body.classList.add("no-scroll");
    }
  };
  const handlerAddNewProperties = async () => {
    // Validate required fields
    if (
      !formValues.propertyName ||
      !formValues.address1 ||
      !formValues.city ||
      !formValues.state ||
      !formValues.postalCode
    ) {
      toast.error("Please fill out required fields.");
      return;
    }
    setIsLoading(true); // Set loading state to true
    const data = new FormData();
    if (formValues.propertyName) data.append("title", formValues.propertyName);
    data.append("address_1", formValues.address1);
    if (formValues.address2) data.append("address_2", formValues.address2);
    if (formValues.city) data.append("city", formValues.city);
    data.append("state", formValues.state);
    data.append("postal_code", formValues.postalCode);
    if (formValues.propertyType) data.append("type", formValues.propertyType);
    if (formValues.propertyCategory)
      data.append("category", formValues.propertyCategory);
    if (formValues.notes) data.append("notes", formValues.notes);

    if (formValues.postThumNailFiles) {
      for (let i = 0; i < formValues.postThumNailFiles.length; i++) {
        data.append("files[]", formValues.postThumNailFiles[i]);
      }
    }

    if (formValues.postThumNailImage) {
      data.append("property_image", formValues.postThumNailImage);
    }
    try {
      const response = await addPropertyData(data);
      if (response && response.data) {
        toast.success(response.data.message);
        setIsLoading(false); // Set loading state to true
      }
    } catch (error) {
      console.error(error);
    }
  };

  const NavMenu = dashboardMenuItem.children_1.map((item) => {
    const isActive = activeItem === item.id;
    const activeClassName = isActive ? "active" : "";

    return (
      <li
        className={`nav-item ${activeClassName}`}
        onClick={() => {
          if (!isPlnActive) {
            item.id === "search" ? setModalShow(true) : setModalShow(false);
          }
        }}
        id={item.id}
        key={item.id}
      >
        <Link
          className={`nav-link ${isPlnActive ? "disabled" : ""} `}
          to={item.url}
          onClick={() => handleItemClick(item.id)}
        >
          <span
            className="nav-icon"
            dangerouslySetInnerHTML={{ __html: item.icon }}
          />
          <span className="menu-title">{item.title}</span>
          {item.id === "notification" && (
            <div className="notify-badge">{notifications.length}</div>
          )}
          {item.id === "search" && (
            <div className="parent-notify-command">
              <div className="notify-command">ctrl F</div>
              <div className="notify-command">
                <img src={commandImg} /> F
              </div>
            </div>
          )}
        </Link>
      </li>
    );
  });

  const NavMenu_2 = dashboardMenuItem.children_2.map((item) => {
    const isActive = activeItem === item.id;
    const activeClassName = isActive ? "active" : "";

    return (
      <li className={`nav-item  ${activeClassName}`} id={item.id} key={item.id}>
        <Link
          className={`nav-link ${isPlnActive ? "disabled" : ""} `}
          to={item.url}
          onClick={() => handleItemClick(item.id)}
        >
          <span
            className="nav-icon"
            dangerouslySetInnerHTML={{ __html: item.icon }}
          />
          <span className="menu-title">{item.title}</span>
          {item.addIcon && (
            <div className="add-icon">
              <img src={menuPlusIcon} />
            </div>
          )}
        </Link>
      </li>
    );
  });

  const NavMenu_3 = dashboardMenuItem.children_3.map((item) => {
    const isActive = activeItem === item.id;
    const activeClassName = isActive ? "active" : "";

    return (
      <li className={`nav-item ${activeClassName}`} id={item.id} key={item.id}>
        <Link
          className={item.id === "logout" ? "nav-link logoutIcon" : "nav-link"}
          to={item.url}
          onClick={() => handleItemClick(item.id)}
        >
          <span
            className="nav-icon"
            dangerouslySetInnerHTML={{ __html: item.icon }}
          />
          <span className="menu-title">{item.title}</span>
        </Link>
      </li>
    );
  });
  const deletePropertiesFunc = () => {
    setIsLoading(true);
    let cloneProperty = [...properties];
    deleteProperties(selectForDelProp?.id)
      .then((resp) => {
        let findPropIndx = cloneProperty.findIndex(
          (prop) => prop?.id === selectForDelProp?.id
        );
        if (findPropIndx > -1) {
          cloneProperty.splice(findPropIndx, 1);
        }
        setProperties(cloneProperty);
        closeDelConfrmModal();
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <nav
        className={`sidebar sidebar-offcanvas ${
          stateSide === "open" ? "active" : ""
        }`}
        id="sidebar"
      >
        <div className="sidebar-inner">
          {selectedProperty ? (
            <>
              <ul
                className={`nav choose-property ${
                  isPlnActive ? "disabled" : ""
                }`}
                id="add-property"
                onClick={handlePropertyClick}
              >
                <div className="choose-property-img">
                  {selectedProperty.thumbnail_url ? (
                    <img
                      src={selectedProperty.thumbnail_url}
                      alt="propertyImg"
                    />
                  ) : (
                    <img src={homeIcon} alt="homeIcon" />
                  )}
                </div>
                <div className="choose-property-title">
                  {selectedProperty.title}
                </div>
                <div className="choose-property-notify">
                  <div className="notify-badge">{properties.length}</div>
                  <div className="choose-property-icons">
                    <img src={CaretUpDown} alt="CaretUpDown" />
                  </div>
                </div>
              </ul>
            </>
          ) : (
            <ul
              className={`nav choose-property ${isPlnActive ? "disabled" : ""}`}
              id="add-property"
              onClick={handleOpenSideNavPropertyAdd}
            >
              <div className="choose-property-img-add">
                <img src={homeIcon} alt="homeIcon" />
              </div>
              <div className="choose-property-title-add">Add new property</div>
              <div className="choose-property-notify">
                <div className="choose-property-icons-add">
                  <img src={menuPlusIcon} alt="menuPlusIcon" />
                </div>
              </div>
            </ul>
          )}

          {/* Floating list of properties */}
          {showPropertyList && (
            <div className="floating-property-list">
              <ul>
                {properties.map((property) => (
                  <li
                    className="d-flex"
                    key={property.id}
                    onClick={() => handleSelectProperty(property)}
                  >
                    {property.title}
                    {selectedProperty?.id !== property?.id && (
                      <DelIcon
                        className="ms-auto"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectForDelProp(property);
                          setDelConfrm(true);
                        }}
                      />
                    )}
                  </li>
                ))}
              </ul>
              {properties?.length < 5 && (
                <ul
                  className="nav choose-property"
                  onClick={handleOpenSideNavPropertyAdd}
                >
                  <div className="choose-property-img-add">
                    <img src={homeIcon} alt="homeIcon" />
                  </div>
                  <div className="choose-property-title-add">
                    Add new property
                  </div>
                  <div className="choose-property-notify">
                    <div className="choose-property-icons-add">
                      <img src={menuPlusIcon} alt="menuPlusIcon" />
                    </div>
                  </div>
                </ul>
              )}
            </div>
          )}

          <ul className="nav">{NavMenu}</ul>
          <div className="nav-divider"></div>
          <ul className="nav">{NavMenu_2}</ul>
          <div className="nav-divider"></div>
          <ul className="nav">{NavMenu_3}</ul>
          <div className="nav-divider"></div>
          <ul className="copyRightContent">
            <span>REmind, 2024</span>
          </ul>
        </div>
        <Link to="/seller/overview" className="affliateProgramCard">
          <div className="affliateProgramCard-title">
            remind Affiliate Program
          </div>
          <div className="affliateProgramCard-des">
            Velit adipiscing ornare arcu at mauris purus quis cursus.
          </div>
          <div className="affliateProgramCard-button">Read more</div>
        </Link>
      </nav>
      <SearchModel show={modalShow} onHide={() => setModalShow(false)} />
      {notificationPopup && (
        <NotificationPopup
          handlerColseNotification={handlerColseNotification}
        />
      )}

      <div
        onClick={handlerCancelSideNav}
        style={{ width: sideNavWidthOverlay }}
        className="sidenav-overlay"
      ></div>

      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
        <PropertyNewSideNav setFormData={setFormValues} />

        <div className="side-footer">
          <button
            className="footer-button-cancel"
            onClick={handlerCancelSideNav}
          >
            Cancel
          </button>

          <button
            className="footer-button-primary "
            disabled={isLoading ? true : false}
            onClick={handlerAddNewProperties}
          >
            {isLoading ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
      <ConfirmationModal
        show={delConfrm}
        handleClose={closeDelConfrmModal}
        loading={isLoading}
        hanndleDelete={deletePropertiesFunc}
      />
    </>
  );
};

export default MainSidebar;

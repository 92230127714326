import React, { useEffect, useState } from "react";
import DataTable, { Alignment, Direction } from "react-data-table-component";
import moment from "moment";
import ReminderSideNavEdit from "./reminderSideNavEdit";
import {
  addCustomReminder,
  addNotificationData,
  delete_notification,
  get_event_lists,
  updateCustomReminder,
} from "../../../axiosCalls/userdashboard";
import { toast } from "react-toastify";
import ReminderSideNavView from "./reminderSideNavView";

const RemindersTable = () => {
  const [events, setEvents] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [reminderID, setReminderID] = useState(null);
  const [continueChange, setContinueChange] = useState("viewStep");
  const [reminderData, setReminderData] = useState([]);

  // Fetch Events
  const fetchEvents = async () => {
    try {
      const res = await get_event_lists();
      setEvents(Array.isArray(res.data) ? res.data : []); // Ensure 'res' is an array, or set it to an empty array
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  // Function to process API events into table-compatible format
  const processEvents = () => {
    if (!events || events.length === 0) return [];

    return events.reduce((acc, event) => {
      const eventDate = moment(event.date).startOf("month").format("YYYY-MM"); // Group by Year-Month, start of the month

      if (!acc[eventDate]) {
        acc[eventDate] = [];
      }
      acc[eventDate].push({
        reminder: event.date,
        description: event.description,
        frequency: event.frequency || "Custom",
        time: event.time,
        name: event.title,
        id: event.ID,
      });
      return acc;
    }, {});
  };

  const groupedEvents = processEvents(); // Group events by month

  // Sort the events by the "Reminder" date in ascending order before rendering
  const rows = Object.entries(groupedEvents).flatMap(([month, monthEvents]) => [
    { month, isMonthRow: true }, // Month row
    ...monthEvents.sort((a, b) => moment(a.reminder).diff(moment(b.reminder))), // Sort by "reminder" date
  ]);

  const handlerChangeStep = async () => {
    try {
      if (
        !reminderData?.date ||
        !reminderData?.title ||
        !reminderData?.frequency
      ) {
        toast.error("Please fill out required fields.");
        return;
      }
      const responseCustomReminder = await updateCustomReminder(reminderData);
      const response = await addNotificationData(
        reminderData,
        responseCustomReminder.data.post_id
      );
      if (response && response.data) {
        if (response.data.status === 400) {
          toast.error(response.message);
        } else {
          toast.success(response.data.message);
          handlerCancelSideNav();
          fetchEvents(); // Fetch events to update data after saving
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const suggestedReminderAdd = (data) => {
    setReminderData(data);
  };

  const columns = [
    {
      name: "Reminder",
      selector: (row) => row.reminder,
      sortable: false,
      cell: (row) =>
        row.isMonthRow ? (
          <div className="month-view">
            {moment(row.month, "YYYY-MM").format("MMMM YYYY")}
          </div>
        ) : (
          <div className="date-view">
            {moment(row.reminder).format("DD ddd")}
          </div>
        ),
    },
    {
      name: "",
      selector: (row) => row.description,
      sortable: false,
    },
    {
      name: "Frequency",
      selector: (row) => row.frequency,
      sortable: false,
    },
    {
      name: "Time",
      selector: (row) => row.time,
      sortable: false,
      cell: (row) =>
        row.time ? moment(row.time, "HH:mm:ss").format("hh:mm A") : "", // Format time to 12-hour
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
  ];

  const [sideNavWidth, setSideNavWidth] = useState("0px");
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState("0%");

  const handleRowSideNavOpen = (e) => {
    if (e.id) {
      setContinueChange("viewStep");
      setSideNavWidth("sidenavPopuWidth");
      setsideNavWidthOverlay("100%");
      document.body.classList.add("no-scroll");
      setReminderID(e.id);
    }
  };

  const handlerChangeToEdit = () => {
    setContinueChange("editStep");
  };

  const handlerCancelSideNav = () => {
    setSideNavWidth("sidenavPopuWidthHide");
    setsideNavWidthOverlay("0%");
    document.body.classList.remove("no-scroll");
  };

  const handlerDeleteCalendar = async () => {
    // Confirmation before deletion
    const confirmed = window.confirm(
      "Are you sure you want to delete this event?"
    );
    if (!confirmed) return;

    try {
      const response = await delete_notification(reminderID);
      if (response.status === 200) {
        toast.warning(response.data.message);
        handlerCancelSideNav();
        fetchEvents(); // Fetch events again after deletion to refresh the list
      } else {
        toast.error(`Something went wrong`);
      }
    } catch (error) {
      toast.error("Error deleting event");
      console.error(error);
    }
  };

  return (
    <div>
      <div className="reminder-table">
        <DataTable
          columns={columns}
          data={rows}
          defaultSortFieldId={0}
          onRowClicked={handleRowSideNavOpen} // Attach click event handler
          pagination={true}
          highlightOnHover={true}
          pointerOnHover={true}
          persistTableHead={true}
          fixedHeaderScrollHeight={"300px"}
          progressPending={loading} // Show loader while data is being fetched
          noTableHead={false}
          noContextMenu={false}
          direction={Direction.AUTO}
          subHeader={false}
          subHeaderAlign={Alignment.LEFT}
          subHeaderWrap={true}
          responsive={true}
        />
      </div>
      <div
        onClick={handlerCancelSideNav}
        style={{ width: sideNavWidthOverlay }}
        className="sidenav-overlay"
      ></div>

      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
        {console.log(reminderID)}
        {continueChange === "viewStep" ? (
          <ReminderSideNavView eventID={reminderID} />
        ) : (
          <ReminderSideNavEdit
            reminder={reminderID}
            suggestedReminderAdd={suggestedReminderAdd}
          />
        )}

        <div className="side-footer">
          <button
            className="footer-button-cancel"
            onClick={handlerCancelSideNav}
          >
            Cancel
          </button>
          {continueChange === "viewStep" ? (
            <button
              className="footer-button-primary"
              onClick={handlerChangeToEdit}
            >
              Edit
            </button>
          ) : (
            <button
              onClick={handlerChangeStep}
              className="footer-button-primary"
            >
              Save
            </button>
          )}
          {continueChange === "viewStep" ? (
            <button
              onClick={handlerDeleteCalendar}
              className="footer-button-delete footer-button-back"
            >
              Delete
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default RemindersTable;

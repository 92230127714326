import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import plusIcon from '../../asstes/dash-icons/plusIcon.svg';
import checkedIcon from '../../asstes/dash-icons/checkedIcon.svg';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import { getSuggestedReminders } from '../../../axiosCalls/userdashboard';

const FilesSideNavEdit = ({ upHandlerCustomReminder, selectValue }) => {
    const [formData, setFormData] = useState({
        fileName: selectValue.file_name || '',
        uploadDate: selectValue.upload_date || '',
        files: selectValue.files || [],
        reminderIds: selectValue.reminder_ids.map(id => String(id)) || [] // Normalize IDs to strings
    });
    const [suggestedReminders, setSuggestedReminders] = useState([]);
    const [selectedReminders, setSelectedReminders] = useState(formData.reminderIds);

    useEffect(() => {
        const fetchReminders = async () => {
            try {
                const response = await getSuggestedReminders();
                setSuggestedReminders(response.data);
                setSelectedReminders(formData.reminderIds);
            } catch (error) {
                console.error("Error fetching reminders:", error);
            }
        };

        fetchReminders();
    }, []);

    useEffect(() => {
        // Update the selected reminders whenever formData is updated
        setSelectedReminders(formData.reminderIds);
    }, [formData.reminderIds]);

    const handleReminderClick = (id) => {
        const normalizedId = String(id); // Normalize the ID to string
        setSelectedReminders((prevSelected) => {
            const newSelected = prevSelected.includes(normalizedId)
                ? prevSelected.filter(reminderId => reminderId !== normalizedId) // Remove if already selected
                : [...prevSelected, normalizedId]; // Add if not selected

            setFormData(prevData => ({
                ...prevData,
                reminderIds: newSelected // Update the reminderIds array in the formData
            }));

            return newSelected;
        });
     };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFormData(prevData => ({
            ...prevData,
            files: [...prevData.files, ...selectedFiles] // Append new files
        }));
    };

    const getFileIcon = (file) => {
        if (file instanceof File) {
            return file.type === 'application/pdf' ? pdfIcon : URL.createObjectURL(file);
        }
        return file.endsWith('.pdf') ? pdfIcon : file; // Return appropriate icon for URLs
    };

    const removeFile = (index) => {
        const updatedFiles = formData.files.filter((_, i) => i !== index);
        setFormData(prevData => ({
            ...prevData,
            files: updatedFiles,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        upHandlerCustomReminder(formData); // Pass formData to parent handler
    }, [formData, upHandlerCustomReminder]);

    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Edit File</h3>
            </div>
            <div className='side-body'>
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>File Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="File name"
                                name="fileName"
                                value={formData.fileName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Upload Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="uploadDate"
                                value={formData.uploadDate}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen uploadFile'>
                                <div className='title'>You can attach different formats: png, pdf, jpg</div>
                                <div className='button'>
                                    <div className="outline-button-upload">
                                        <input
                                            type='file'
                                            className='chooeseFile'
                                            multiple
                                            accept='.png, .pdf, .jpg'
                                            onChange={handleFileChange}
                                        />
                                        Upload file
                                    </div>
                                </div>
                            </div>
                            <div className='listOfFiles'>
                                {formData.files.map((file, index) => (
                                    <div className='image-choosed' key={index}>
                                        <a href={file instanceof File ? URL.createObjectURL(file) : file} target='_blank' rel='noopener noreferrer'>
                                            <img src={getFileIcon(file)} alt='File preview' />
                                        </a>
                                        <button
                                            onClick={() => removeFile(index)}
                                            type='button'
                                            className='image-choosed-crossed'
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                    </Row>

                    <div className='mb-3'>
                        <h1 className='side-nav-main-heading'>Suggested reminders</h1>
                    </div>

                    {suggestedReminders.map((item) => (
                        <div
                            className='suggetes-item'
                            key={item.id}
                            onClick={() => handleReminderClick(item.id)}
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            <div className='titleSuggestItem'>{item.title}</div>
                            <div>
                                <img src={selectedReminders.includes(String(item.id)) ? checkedIcon : plusIcon} alt="icon" />
                            </div>
                        </div>
                    ))}
                </Form>
            </div>
        </div>
    );
};

export default FilesSideNavEdit;

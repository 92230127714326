import React, { useRef, useState } from "react";
import { Card, Modal, Spinner } from "react-bootstrap";
import { ReactComponent as GalleryIcon } from "../../user-dashboard/asstes/dash-icons/gallery.svg";
import { ReactComponent as CameraIcon } from "../../user-dashboard/asstes/dash-icons/camera_one.svg";
import { ReactComponent as DelIcon } from "../../user-dashboard/asstes/dash-icons/deleteIcon.svg";
import pdfIcon from "../../user-dashboard/asstes/dash-icons/pdfIcon.svg";
import styles from "./style.module.css";
import { toast } from "react-toastify";
import { uploadFile } from "../../axiosCalls/userdashboard";
const actionEnum = {
  camera: "Camera",
  gallery: "Gallery",
};
export default function PermissionModal({
  shown,
  onCloseModal,
  selectedQuickAction,
  fileDataHandler,
}) {
  const [pickAction, setPickAction] = useState("");
  const [pictureCapture, setPictureCap] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const getVideo = async () => {
    try {
      setSelectedFile(null);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setPickAction(actionEnum.camera);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      toast.error("Please Allow Permission");
      console.error("Error accessing camera: ", err);
      setPickAction("");
    }
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0);

    const imageData = canvas.toDataURL("image/png");
    const fileName = "image.png";
    const imageFile = base64ToFile(imageData, fileName);
    setPictureCap(imageData);
    submitFunc(imageFile);
    closeCamera();
  };
  function base64ToFile(base64String, fileName) {
    // Split the Base64 string into two parts: the metadata and the actual Base64 data
    const [metadata, base64Data] = base64String.split(",");

    // Decode the Base64 data
    const byteCharacters = atob(base64Data);

    // Create a Uint8Array to hold the binary data
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], {
      type: metadata.split(":")[1].split(";")[0],
    });

    // Create a File from the Blob
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  }
  const removeImage = () => {
    setPictureCap("");
    getVideo();
  };
  const removeGallery = () => {
    setSelectedFile(null);
  };
  const onClose = () => {
    setPictureCap("");
    closeCamera();
    canvasRef.current = null;
    videoRef.current = null;
    setPickAction("");

    onCloseModal();
  };
  const closeCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };
  const submitFunc = async (file) => {
    let obj = {
      files: file,
      quickActionType: selectedQuickAction.id,
    };
    setIsLoading(true);
    uploadFile(obj)
      .then(({ data: { data } }) => {
        fileDataHandler(data);
        onClose();
      })
      .catch((err) => toast.error("Failed to extract data"))
      .finally(() => setIsLoading(false));
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      (file && file.type === "application/pdf") ||
      file.type.includes("image")
    ) {
      setSelectedFile(file);
      submitFunc(file);
    } else {
      alert("Please upload a valid PDF file.");
      setSelectedFile(null);
    }
  };
  return (
    <>
      <Modal
        show={shown}
        onHide={onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="pt-4 pe-4" closeButton></Modal.Header>
        <Modal.Body>
          <h3 className="ms-4">You can take up by </h3>

          {pictureCapture ? (
            <>
              <div className={styles["del-container"]} onClick={removeImage}>
                <DelIcon className={styles["icon"]} />
              </div>
              <img src={pictureCapture} width={"100%"} />
            </>
          ) : (
            <>
              <canvas ref={canvasRef} style={{ display: "none" }} />
              <video
                ref={videoRef}
                autoPlay
                style={
                  pickAction === actionEnum.camera
                    ? { width: "100%" }
                    : { height: 0 }
                }
              />
            </>
          )}

          {selectedFile ? (
            <div className="position-relative">
              <p className="ms-4">{selectedFile?.name || ""}</p>
              <div
                className={styles["del-container-gallery"]}
                onClick={removeGallery}
              >
                <DelIcon className={styles["icon"]} />
              </div>
              <GalleryIcon
                width={"50%"}
                height={"50%"}
                className="mx-auto d-flex"
              />
            </div>
          ) : pickAction !== actionEnum.camera ? (
            <div className="d-flex justify-content-between flex-wrap">
              <ImageTextCard
                Icon={CameraIcon}
                onClick={getVideo}
                title={"Camera"}
              />
              <input
                type="file"
                name="gallery"
                id="gallery"
                accept="image/*,application/pdf"
                className="d-none"
                onChange={handleFileChange}
              />
              <label htmlFor="gallery" className="col-12 col-md-6 ">
                <ImageTextCard Icon={GalleryIcon} title={"Gallery"} />
              </label>
            </div>
          ) : null}
          {(pickAction === actionEnum.camera || selectedFile) && (
            <div className="d-flex justify-content-end mt-4 me-4">
              <button className={styles["cancel"]} onClick={onClose}>
                Cancel
              </button>
              <button
                className={styles["action-button"]}
                disabled={pictureCapture ? false : selectedFile ? true : false}
                onClick={captureImage}
              >
                {isLoading ? (
                  <Spinner />
                ) : pictureCapture || selectedFile ? (
                  "Submit"
                ) : (
                  "Take Picture"
                )}
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
const ImageTextCard = ({ Icon, title, onClick }) => {
  return (
    <Card
      className={styles["action-option"]}
      onClick={onClick}
      style={{ width: "18rem", height: "18rem", margin: "20px auto" }}
    >
      <Card.Body className="d-flex flex-column align-items-center justify-content-center">
        <Icon width="50%" height="50%" />
        <Card.Title className="mt-3">{title}</Card.Title>
      </Card.Body>
    </Card>
  );
};

import React from "react";
import styles from "./style.module.css";
import { ReactComponent as MenuPlusIcon } from "../../asstes/dash-icons/menuPlusIcon.svg";
export default function AddHomeEssential({ onClick, heading }) {
  return (
    <div className={styles.AddCard} onClick={onClick}>
      <div className={styles.Detail}>
        <MenuPlusIcon className={styles["icon"]} />
        <span className={styles.label}>{heading}</span>
      </div>
    </div>
  );
}

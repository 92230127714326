import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import giftIconDash from '../assets/icons/giftIconDash.svg'
import sellerGiftIcon from '../assets/icons/seller-gift-icon.svg'
import swtichIcon from '../assets/icons/refresh-cw.svg';
import whiteGift from '../assets/icons/whiteGift.svg'
import saveIcon from '../assets/icons/saveIcon.svg'
import historyIcon from '../assets/icons/historyIcon.svg'
import { Form } from 'react-bootstrap';
import RecentlyGiftedSubscriptions from '../inc/RecentlyGiftedSubscriptions';
import RecentlyReferred from '../inc/RecentlyReferred';
import ShowCodeHistory from '../inc/ShowCodeHistory';
import refreshcw from '../assets/icons/refresh-cw-blue.svg'
import SelectDropDownYTD from '../inc/selectDropDownYTD';
import GiftNowPopup from '../inc/giftNowPopup';
import ReferNowPopup from '../inc/referNowPopup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { addDataToReferalCode, createPaymentIntent, getDataGiftRemind, getDataReferRemind, getDataReralCode } from '../../axiosCalls/seller';
import { toast } from 'react-toastify';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY);

const GiftReferReminders = () => {
    const [giftTabs, setGiftTabs] = useState('giftedSub');
    const [modalShow, setModalShow] = useState(false);
    const [subScriptionData, setSubScriptionData] = useState([]);
    const [dataReralCodeData, setDataReralCodeData] = useState([]);
    const [referReminds, setReferReminds] = useState([]);
    const [modalShowRefer, setModalShowRefer] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [referralCode, setReferralCode] = useState('');
   const fetchBasicData = async () => {
            const res = await getDataGiftRemind();
            const resReferRemind = await getDataReferRemind();
            const resReralCode = await getDataReralCode();

            if (res.status == 200) {
                setSubScriptionData(res.data)
            }
            if(resReralCode.status == 200){
                setDataReralCodeData(resReralCode.data);
            }
            if(resReferRemind.status == 200){
                setReferReminds(resReferRemind.data);
            }
        }
    useEffect(() => {


        fetchBasicData()

    }, [])
    // Function to generate a random 15-character code
    const generateCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let code = '';
        for (let i = 0; i < 15; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setReferralCode(code); // Set the generated code in state
    };

    const handlerClosePopup = (set) => {
        setModalShow(set)

    }
    const handlerClosePopupRefer = (set) => {
        setModalShowRefer(set)

    }
    useEffect(() => {
        handlePaymentIntent();

    }, [])

    const handlePaymentIntent = async () => {
        try {
            const response = await createPaymentIntent();
            const data = response.data;
            if (data && data.client_secret) {

                setClientSecret(data.client_secret);
            } else {
                console.error('Payment intent creation failed:', data);
            }
        } catch (error) {
            console.error('Error creating payment intent:', error);
        }
    };
    const togglePopup = () => {
        setModalShow(true)
    };

    const togglePopupRefer = () => {
        setModalShowRefer(true)
    };
    const handlerSubmitReferalCode = async () => {

        if(!referralCode){
            toast.error("Referal Code is required");
            return;
        }
        const data = {
            referral_code : referralCode,
            status: "active"
        }
        try {
         const response = await addDataToReferalCode(data);
         console.log(response)
         if(response.data){
            toast.success("Referal Code is added successfully");
         }

        } catch (error) {

        }


    }



    const homeEssentials = {
        items: [
            {
                icon: giftIconDash,
                iconAllow: true,
                heading: "Subscriptions Gifted",
                btnText: 'Gift Now',
                count: subScriptionData.length ? subScriptionData.length : 0,
                description: 'Number of Subscription gifted',
                dropdown: true

            },
            {
                icon: sellerGiftIcon,
                iconAllow: false,
                heading: "Redeemed Referrals",
                btnText: 'Refer Now',
                count: referReminds.length ? referReminds.length : 0,
                description: 'Number of Referral Codes Redeemed',
                dropdown: true
            }

        ],
    };
    const handerSellerGiftTab = (type) => {
        setGiftTabs(type);
    }


    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">
                        <div className=" paddingRight0">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-4">Gift or Refer remind</h5>
                                </div>
                            </div>
                        </div>
                        <div className=" ">
                            <div className="row">
                                {homeEssentials.items.map((card) => {
                                    return (
                                        <div className="col-lg-4 item_each_home_cards">
                                            <div className="dashboard-card">
                                                <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                    <div className=" justify-content-between align-items-center ">
                                                        <div className="icon-box-card">
                                                            <img src={card.icon} />
                                                            <p className="dashboard-subheading">
                                                                {card.count}
                                                            </p>
                                                            {
                                                                card.dropdown ?
                                                                    <div className='seller-card-dropdown'>
                                                                        <SelectDropDownYTD />
                                                                    </div>
                                                                    : ''}
                                                        </div>
                                                        <p className="dashboard-heading">{card.heading}</p>
                                                        <p className="dashboard-description">
                                                            {card.description}
                                                        </p>
                                                        <Link className='seller-card-button' onClick={card.btnText === 'Gift Now' ? togglePopup : togglePopupRefer} >
                                                            {card.iconAllow && <img src={whiteGift} alt='whiteGift' />}

                                                            {card.btnText}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="col-lg-4 item_each_home_cards lastSellerItem">
                                    <div className="dashboard-card ">
                                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                            <div className=" referralCode mb-2">
                                                <div className="icon-box-card">
                                                </div>
                                                <p className="dashboard-heading">Generate Referral Code
                                                    <div className='refresh-redeemed-code' onClick={generateCode}>
                                                        <img src={refreshcw} alt='refresh' /><span>New Code</span></div>
                                                </p>
                                                <p className="dashboard-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                                <p className='dashboard-card-textfiled-parent'>
                                                    <input type='text' className='dashboard-card-textfiled' value={referralCode} readOnly />
                                                    <button type='button' className='dashboard-card-submit' onClick={handlerSubmitReferalCode}> <img src={saveIcon} alt='saveIcon' />Save</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className="row mb-3">
                                        <div className="col-md-6 ">
                                            <div className='referalTabs'>
                                                <h2 onClick={() => handerSellerGiftTab('giftedSub')} className={giftTabs === 'giftedSub' ? ' card-heading tabActive' : 'card-heading'}>Recent Gifted Subscriptions</h2>
                                                <h2 onClick={() => handerSellerGiftTab('recentRefd')} className={giftTabs === 'recentRefd' ? ' card-heading tabActive' : 'card-heading'}>Recent Referral Codes Redeemed</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6 card-right-action">
                                            <div className='recent-earning-sortlble'>
                                                <div onClick={() => handerSellerGiftTab('history')} className={giftTabs === 'history' ? 'tabActive seller-switch-role' : 'seller-switch-role'}>
                                                    <img src={historyIcon} alt='switch-icon' /> Show Code History
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {giftTabs === 'giftedSub' ? <RecentlyGiftedSubscriptions Giftdata={subScriptionData} /> : giftTabs === 'recentRefd' ? <RecentlyReferred referData={referReminds}  /> : <ShowCodeHistory refreshData={fetchBasicData} referData={dataReralCodeData} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {clientSecret ? <Elements stripe={stripePromise} options={{ clientSecret }}>
                <GiftNowPopup show={modalShow} handlerClosePopup={handlerClosePopup} step="first" onHide={() => setModalShow(false)} />
            </Elements>
                : ''}
            <ReferNowPopup refreshData={fetchBasicData} referData={dataReralCodeData} show={modalShowRefer} handlerClosePopupRefer={handlerClosePopupRefer} step="first" onHide={() => setModalShowRefer(false)} />
        </div>
    );
}

export default GiftReferReminders;

import React, { useState, useEffect } from 'react';
import DataTable, { Alignment, Direction } from "react-data-table-component";
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg';
import refetchIcon from '../../asstes/dash-icons/refetchIcon.svg'; 
import { Modal, Button } from 'react-bootstrap'; 
import { getHomeEsstentialReminders } from '../../../axiosCalls/userdashboard';

const LinkReminders = ({ type, onRemindersUpdate }) => {
    const [reminders, setReminders] = useState([]);  
    const [loading, setLoading] = useState(true);    
    const [selectedReminder, setSelectedReminder] = useState(null);  
    const [showModal, setShowModal] = useState(false);  

    const fetchReminders = async () => {
        setLoading(true); 
        try {
            const response = await getHomeEsstentialReminders(type);  
            const updatedReminders = response.data.map(reminder => ({
                ...reminder,
                start_date: reminder.start_date || new Date().toISOString().split('T')[0] // Set current date if start_date is null
            }));
            setReminders(updatedReminders);  
            onRemindersUpdate(updatedReminders);  
            setLoading(false);  
        } catch (error) {
            console.error('Error fetching reminders:', error);
            setLoading(false);  
        }
    };

    useEffect(() => {
        fetchReminders();  
    }, [type]);  

    const handleViewDetails = (reminder) => {
        setSelectedReminder(reminder);  
        setShowModal(true);  
    };

    const handleDeleteReminder = (reminderId) => {
        const updatedReminders = reminders.filter(reminder => reminder.id !== reminderId);  
        setReminders(updatedReminders);  
        onRemindersUpdate(updatedReminders);  
    };

    // Handle date change
    const handleDateChange = (id, newDate) => {
        const updatedReminders = reminders.map(reminder => 
            reminder.id === id ? { ...reminder, start_date: newDate } : reminder
        );
        setReminders(updatedReminders);
        onRemindersUpdate(updatedReminders);  // Send updated reminders to the parent component
    };

    const columns = [
        {
            name: "Reminder Title",
            selector: (row) => row.reminderTitle,
            sortable: false,
            reorder: true,
        },
        {
            name: "Frequency",
            selector: (row) => row.frequency,
            sortable: false,
            reorder: true,
        },
        {
            name: "Start Date",
            selector: (row) => row.start_date,
            sortable: false,
            reorder: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },
    ];

    const data = reminders.map((reminder) => ({
        id: reminder.id,
        reminderTitle: <div className='REminderTitle'>{reminder.title}</div>,
        frequency: <div className='Frequency'>{reminder.frequency}</div>,
        action: (
            <div className='actions'>
                <span onClick={() => handleViewDetails(reminder)}>
                    <img src={eyeIcon} alt="View" />
                </span>
                <span onClick={() => handleDeleteReminder(reminder.id)}>
                    <img src={deleteIcon} alt="Delete" />
                </span>
            </div>
        ),
        start_date: (
            <div className='reminder-start-date'>
                <input 
                    type='date' 
                    className='form-control' 
                    value={reminder.start_date} 
                    onChange={(e) => handleDateChange(reminder.id, e.target.value)} 
                />
            </div>
        )
    }));

    return (
        <>
            <img
                src={refetchIcon}
                alt="Refetch"
                style={{ cursor: 'pointer', float: 'right', marginRight: '20px' }}
                onClick={fetchReminders}  
            />

            <div className="linkReminderTable">
                <DataTable
                    columns={columns}
                    data={data}
                    defaultSortFieldId={2}
                    pagination={false}
                    highlightOnHover={true}
                    progressPending={loading}  
                    direction={Direction.AUTO}
                    responsive={true}
                />
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reminder Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedReminder && (
                        <>
                            <p><strong>Title:</strong> {selectedReminder.title}</p>
                            <p><strong>Description:</strong> {selectedReminder.description}</p>
                            <p><strong>Frequency:</strong> {selectedReminder.frequency}</p>
                            <p><strong>Date:</strong> {selectedReminder.date}</p>
                            <p><strong>Time:</strong> {selectedReminder.time}</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LinkReminders;

import React from 'react';
import { axiosInstance } from "./axiosInstance";
import { toast } from 'react-toastify';

// API call to log in the user
export const loginApi = async (email, password) => {
    let res;
    try {
        const data = JSON.stringify({
            username_or_email: email,
            password: password,
        });
        res = await axiosInstance.post("/login", data);
    } catch (error) {

    }
    return res ? res : "";
};


// API call to get the list of plans
export const plansList = async () => {
    let res;
    try {
        res = await axiosInstance.get("/get-plans");
    } catch (error) {

    }
    return res ? res : "";
};

// API call to sign up a new user
export const signUpApis = async (data) => {
    let res;
    try {
        res = await axiosInstance.post("/register", data);
    } catch (error) {

    }
    return res ? res : "";
};

// API call to initiate forgot password process
export const forgotPassword = async (data) => {
    let res;
    try {
        res = await axiosInstance.post("/forgot-password", data);
    } catch (error) {

    }
    return res ? res : "";
};

// API call to reset the user's password
export const resetPassword = async (data) => {
    let res;
    try {
        res = await axiosInstance.post("/reset-password", data);
    } catch (error) {

    }
    return res ? res : "";
};


// API call to update user profile
export const updateProfile = async (data,newPassword) => {
    let res;
        data['password'] = newPassword;
    try {
         res = await axiosInstance.post("/update-profile", data);
        // toast.success("Your profile has been updated successfully");

    } catch (error) {

    }
    return res ? res : "";
};

export const verifyPassword = async (data) =>{
    let res;
    try {
        res = await axiosInstance.post("/verify-password", data);

    } catch (error) {

    }
    return res ? res : "";
}
export const getPlanById = async (planID) => {
    let res;
    try {
        res = await axiosInstance.get(`/get-plan/${planID}`);

    } catch (error) {

    }
    return res ? res : "";


}


// login with google
export const loginWithGoogle = async (idToken) => {
    let res;
    try {
        res = await axiosInstance.post(`/google-login`,idToken);

    } catch (error) {

    }
    return res ? res : "";


}
import { useState } from "react";
import { updateProfile } from "../../../axiosCalls/auth";

const reminderOnbarod = {
  showProgress: "true",
  steps: [
    {
      element: "#reminders-search",
      popover: {
        title: "Search",
        description: "You can search reminders here",
      },
    },
    {
      element: "#google-calendar",
      popover: {
        title: "Google Calendar",
        description: "You can connect google calendar ",
      },
    },
    {
      element: "#add-reminder-calendar",
      popover: {
        title: "Add Reminder",
        description: "By click this button, you can add reminders",
      },
    },
  ],
};
const servicesOnboard = {
  showProgress: "true",
  steps: [
    {
      element: "#services-search",
      popover: {
        title: "Search",
        description: "You can search services here",
      },
    },
    {
      element: "#expense-detail",
      popover: {
        title: "Expense",
        description: "You can see expense detail by click this button",
      },
    },
    {
      element: "#add-purchase-service",
      popover: {
        title: "Add Purchase & Service",
        description: "You can add purchases and services here",
      },
    },
  ],
};
const projectOnboard = {
  showProgress: "true",
  steps: [
    {
      element: "#add-project",
      popover: {
        title: "Add Project",
        description: "You can add project by complete the form",
      },
    },
  ],
};
const fileOnboard = {
  showProgress: "true",
  steps: [
    {
      element: "#search-file",
      popover: {
        title: "Search",
        description: "You can search file through",
      },
    },
    {
      element: "#filter-file",
      popover: {
        title: "Filter",
        description: "Filter out data by selecting filters",
      },
    },
    {
      element: "#add-file",
      popover: {
        title: "Add File",
        description: "You can add a file by filling out the form details",
      },
    },
  ],
};
const propertyReportOnboard = {
  showProgress: "true",
  steps: [
    {
      element: "#export-print",
      popover: {
        title: "Export & Print",
        description: "You can export & print data",
      },
    },
    {
      element: "#property-filter",
      popover: {
        title: "Property Filter",
        description: "You can apply all those filters",
      },
    },
    {
      element: "#expense-total",
      popover: {
        title: "Expense Total",
        description:
          "Here is the expense total section which is shown all total expenses",
      },
    },
    {
      element: "#expense-detail",
      popover: {
        title: "Expense Detail",
        description:
          "Here is the expense detail section which is shown all expense detail",
      },
    },
    {
      element: "#task-detail-overview",
      popover: {
        title: "Task Detail & Overview",
        description: "Here is the section for task detail & overview",
      },
    },
    {
      element: "#property-warranty",
      popover: {
        title: "Property Warranty",
        description: "Shown Property Warranties",
      },
    },
    {
      element: "#project-cost",
      popover: {
        title: "Project",
        description: "Shown Project Cost",
      },
    },
    {
      element: "#property-files",
      popover: {
        title: "Property Files",
        description: "Shown all property files",
      },
    },
  ],
};
const entireDriver = {

  onPopoverRender: async (popover, { config, state }) => {
    const totalSteps = config.steps.length - 1;
    const activeStep = state.activeIndex;

    if (activeStep === totalSteps) {
      localStorage.setItem("mainTour", JSON.stringify(true));

    const storedUser = localStorage.getItem("user");
    const userData = JSON.parse(storedUser);

    const updatedUser = {
      ...userData,
      meta_data: {
        ...userData.meta_data,
        mainTour: true,
      },
    };
    try {
      const response = await updateProfile({
        ...updatedUser,

      });
      if (response && response.data) {

        localStorage.setItem("user", JSON.stringify(response.data));
      }
    } catch (error) {}
   }
  },

  showProgress: "true",
  steps: [
    {
      element: "#add-property",
      popover: {
        title: "Add Property",
        description: "Before we get started, let's create your first property",
      },
    },
    {
      element: "#home-essentials",
      popover: {
        title: "Home Essential",
        description:
          "Great! Now let’s add some home essentials for your property.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#reminders",
      popover: {
        title: "Reminders",
        description: "Get reminders on the go",
      },
    },
    {
      element: "#purchases-services",
      popover: {
        title: "Purchase and Services",
        description:
          "Keep info about all the purchases and services and track future expenses to help meet financial goals.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#projects",
      popover: {
        title: "Projects",
        description: "",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#files",
      popover: {
        title: "Files",
        description:
          "Let’s upload some files related to the property (insurance documents, tax documents, association bylaws, warranties, etc.). Click on ‘Files’ tab to visit a necessary page.",
        side: "right",
        align: "start",
      },
    },
  ],
};

export {
  reminderOnbarod,
  servicesOnboard,
  projectOnboard,
  entireDriver,
  fileOnboard,
  propertyReportOnboard,
};

import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Select } from './Select';
import LinkReminders from './linkReminders';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import { fetchHomeEssentials, getPurchaseList, getServiceList } from '../../../axiosCalls/userdashboard';

const ProjectsSideNavNew = ({ handleFormData }) => {
    const [formData, setFormData] = useState({
        project_name: '',
        start_date: '',
        end_date: '',
        notes: '',
        linked_purchases: [],
        linked_services: [],
        linked_home_essentials: [],
        files: [],
        images: [],
        property_id: '2967',
    });

    const [homeEssentials, setHomeEssentials] = useState([]);
    const [purchaseList, setPurchaseList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [selectedHomeEssentials, setSelectedHomeEssentials] = useState([]);
    const [selectedServicesList, setSelectedServicesList] = useState([]);
    const [selectedPurchaseList, setSelectedPurchaseList] = useState([]);





    useEffect(() => {
        const fetchEssentials = async () => {
            try {
                const response = await fetchHomeEssentials();
                const optionsData = response.data.items.map(item => ({
                    label: `${item.type} - ${item.model_number}`,
                    value: item.id,
                }));
                setHomeEssentials(optionsData);
            } catch (error) {
                console.error("Error fetching home essentials:", error);
            }
        };

        const fetchPurchases = async () => {
            try {
                const response = await getPurchaseList();
                const projectOptions = response.data.map(p => ({
                    value: p.id,
                    label: p.product_name,
                }));
                setPurchaseList(projectOptions);
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        const fetchServices = async () => {
            try {
                const response = await getServiceList();
                const projectOptions = response.data.map(p => ({
                    value: p.id,
                    label: p.service_name,
                }));
                setServicesList(projectOptions);
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        };

        fetchServices();
        fetchPurchases();
        fetchEssentials();
    }, []);

    // Handle input change for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle date change
    const handleDateChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle file selection
    const handleFileChange = (e, type) => {
        const selectedFiles = Array.from(e.target.files);
        setFormData({
            ...formData,
            [type]: [...formData[type], ...selectedFiles],
        });
    };

    // Handle Select changes
    const handleSelectChange = (selectedOptions, field) => {
         if(field === 'linked_home_essentials'){
            setSelectedHomeEssentials(selectedOptions);
        } else if(field === 'linked_services'){
            setSelectedServicesList(selectedOptions);

        }else{
            setSelectedPurchaseList(selectedOptions);

        }
        setFormData({
            ...formData,
            [field]: selectedOptions,
        });
    };

    // Pass form data to the parent component
    useEffect(() => {
        handleFormData(formData);
    }, [formData]);

    // Function to get the appropriate icon for files
    const getFileIcon = (file) => {
        if (file.type === 'application/pdf') {
            return pdfIcon; // PDF icon
        } else {
            return URL.createObjectURL(file); // Image preview
        }
    };

    // Remove file from the list
    const removeFile = (index, type) => {
        const updatedFiles = formData[type].filter((_, i) => i !== index);
        setFormData({
            ...formData,
            [type]: updatedFiles,
        });
    };
    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Add Project</h3>
            </div>
            <div className='side-body'>
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Project Name <span className="requiredStar">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="project_name"
                                placeholder="Name"
                                value={formData.project_name}
                                required
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Start Date <span className="requiredStar">*</span></Form.Label>
                            <Form.Control
                                type="date"
                                name="start_date"
                                value={formData.start_date}
                                required
                                onChange={(e) => handleDateChange('start_date', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="end_date"
                                value={formData.end_date}
                                onChange={(e) => handleDateChange('end_date', e.target.value)}
                            />
                        </Form.Group>

                        {/* Files Upload */}
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Files</div>
                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        Add file
                                        <input
                                            type='file'
                                            name='files'
                                            multiple
                                            accept='.png,.pdf,.jpg,.jpeg'
                                            className='chooeseFile'
                                            onChange={(e) => handleFileChange(e, 'files')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='listOfFiles'>
                                {formData.files.map((file, index) => (
                                    <div className='image-choosed' key={index}>
                                        <a href={URL.createObjectURL(file)} target='_blank' rel='noopener noreferrer'>
                                            <img src={getFileIcon(file)} alt='File preview' />
                                        </a>
                                        <button
                                            onClick={() => removeFile(index, 'files')}
                                            className='image-choosed-crossed'
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        {/* Images Upload */}
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Images</div>
                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        Add image
                                        <input
                                            type='file'
                                            name='images'
                                            multiple
                                            accept='.png,.jpg,.jpeg'
                                            className='chooeseFile'
                                            onChange={(e) => handleFileChange(e, 'images')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='listOfImages'>
                                {formData.images.map((image, index) => (
                                    <div className='image-choosed' key={index}>
                                        <a href={URL.createObjectURL(image)} target='_blank' rel='noopener noreferrer'>
                                            <img src={URL.createObjectURL(image)} alt='Image preview' />
                                        </a>
                                        <button
                                            onClick={() => removeFile(index, 'images')}
                                            className='image-choosed-crossed'
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        {/* Linked Purchases */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Linked Purchases</Form.Label>
                            <Select
                                multiple
                                options={purchaseList}
                                value={selectedPurchaseList}
                                onChange={(selected) => handleSelectChange(selected, 'linked_purchases')}
                            />
                        </Form.Group>

                        {/* Linked Services */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Linked Services</Form.Label>
                            <Select
                                multiple
                                options={servicesList}
                                value={selectedServicesList}
                                onChange={(selected) => handleSelectChange(selected, 'linked_services')}
                            />
                        </Form.Group>

                        {/* Linked Home Essentials */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Linked Home Essentials</Form.Label>
                            <Select
                                multiple
                                options={homeEssentials}
                                value={selectedHomeEssentials}
                                onChange={(selected) => handleSelectChange(selected, 'linked_home_essentials')}
                            />
                        </Form.Group>

                        {/* Notes */}
                        <Form.Group as={Col} className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="notes"
                                rows={3}
                                placeholder='Type here...'
                                value={formData.notes}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default ProjectsSideNavNew;

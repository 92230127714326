import { axiosInstance } from "./axiosInstance";

export const fetchWalletBalance = async () => {
  let res;
  const userId = JSON.parse(localStorage.getItem('user')).user_id;
  try {
    res = await axiosInstance.get(`/balance/${userId}`);
  } catch (error) { }
  return res ? res : "";
};

export const createPaymentIntent = async () => {
  let res;
  try {
    res = await axiosInstance.post(`/create-payment-intent`);
  } catch (error) { }
  return res ? res : "";
}
export const createPaymentSubIntent = async (payload) => {
  let res;
  try {
    res = await axiosInstance.post(`/create-sub-payment-intent`,payload);
    console.log(res);
  } catch (error) { }
  return res ? res.data : "";
}

export const addDataToGiftRemind = async (data) => {
  let res;
  const userId = JSON.parse(localStorage.getItem('user')).user_id;
  try {
    res = await axiosInstance.post(`/add-affiliate-gift-remind`, data);
  } catch (error) { }
  return res ? res : "";
}
export const addDataToReferalCode = async (data) => {
  let res;

  try {
    res = await axiosInstance.post(`/add-referral-code`, data);
  } catch (error) { }
  return res ? res : "";
}
export const addSubmitReferalData = async (data) => {
  let res;

  try {
    res = await axiosInstance.post(`/add-refer-remind`, data);
  } catch (error) { }
  return res ? res : "";
}

export const getDataGiftRemind = async () => {
  let res;
  try {
    res = await axiosInstance.get(`/gift-reminds`);

  } catch (error) {

  }
  return res ? res : "";

}
export const getDataReralCode = async () => {
  let res;
  try {
    res = await axiosInstance.get(`/referral-codes`);

  } catch (error) {

  }
  return res ? res : "";

}
export const updateReralCode = async (id, data) => {
  let res;
  try {
    res = await axiosInstance.post(`/update-referral-code/${id}`, data);

  } catch (error) {

  }
  return res ? res : "";

}

export const getDataReferRemind = async () => {
  let res;
  try {
    res = await axiosInstance.get(`/refer-reminds`);

  } catch (error) {

  }
  return res ? res : "";
}
export const getDataMyNetwork = async () => {
  let res;
  try {
    res = await axiosInstance.get(`/my-network`);

  } catch (error) {

  }
  return res ? res : "";
}

export const getAccountIdFromCode = async (code) =>{
  let res;
  try {
    res = await axiosInstance.post(`/get-account-id`, code);

  } catch (error) {

  }
  return res ? res : "";
}

export const  getEarningList  = async () => {
  let res;
  try {
    res = await axiosInstance.get(`/earnings`);
  } catch (error){

  }
  return res ? res : "";
}

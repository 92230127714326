import React, { useEffect, useState, useCallback } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import placeholderImage from "../../asstes/dash-icons/placeholderImage.svg";
import pdfIcon from "../../asstes/dash-icons/pdfIcon.svg";
import {
  getPropertyCategory,
  getPropertyFromGoogle,
  getPropertyTypes,
  getStates,
} from "../../../axiosCalls/userdashboard";
import MagnifyingGlass from "../../asstes/dash-icons/sidebar/MagnifyingGlass.svg";

const PropertyNewSideNav = ({ setFormData }) => {
  const [propertyCheck, setPropertyCheck] = useState(false);
  const [states, setStates] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyCategories, setPropertyCategories] = useState([]);
  const [propertyImage, setPropertyImage] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [images, setImages] = useState([]);
  const [postThumNailImage, setPostThumNailImage] = useState();
  const [postThumNailFiles, setPostThumNailFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [formValues, setFormValues] = useState({
    propertyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postalCode: "",
    propertyType: "",
    propertyCategory: "",
    notes: "",
  });

  useEffect(() => {
    setPropertyCheck(false);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    const newFile = [];
    const file = event.target.files[0];

    setPostThumNailImage(file);

    setFormValues({
      ...formValues,
      ["postThumNailImage"]: file,
    });

    if (file) {
      const url = URL.createObjectURL(file);
      setImageURL(url);
      newFile.push({
        id: Math.random(),
        name: file.name,
        type: file.type,
        url,
      });
      setPropertyImage(newFile);
    }
  };

  const handleImagesChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = [];

    if (images.length + selectedFiles.length > 10) {
      alert("You can upload a maximum of 10 files.");
      return;
    }

    setFormValues({
      ...formValues,
      ["postThumNailFiles"]: selectedFiles,
    });

    setPostThumNailFiles(selectedFiles);

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const url = URL.createObjectURL(file);
      newFiles.push({
        id: Math.random(),
        name: file.name,
        type: file.type,
        url,
      });
    }

    setImages((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeImage = (id) => {
    setImages((prevImages) => prevImages.filter((img) => img.id !== id));
  };

  const handleRemoveImage = () => {
    setImageURL(null);
  };

  const fetchBasicData = useCallback(async () => {
    try {
      const responseStates = await getStates();
      setStates(responseStates.data);
      setFormValues({
        ...formValues,
        state: responseStates?.data[0]?.name || "",
      });
    } catch (error) {
      console.error(error);
    }

    try {
      const responsePropertyTypes = await getPropertyTypes();
      setPropertyTypes(responsePropertyTypes.data);
    } catch (error) {
      console.error(error);
    }

    try {
      const responsePropertyCategories = await getPropertyCategory();
      setPropertyCategories(responsePropertyCategories.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchBasicData();
  }, [fetchBasicData]);

  useEffect(() => {
    setFormData({ ...formValues, postThumNailFiles, postThumNailImage });
  }, [formValues, postThumNailFiles, postThumNailImage]);
  const handlerCustomProperty = () => {
    setPropertyCheck(true);
  };

  const handleSelectProperty = (property) => {
    const parts = property.formatted_address.split(",");
    const cityStateZip = parts[2].trim().split(" "); // This takes the second part (city, state, zip)
    const city = parts[1].trim(); // Join all but last two parts for the city
    const state = cityStateZip[cityStateZip.length - 2]; // Second last part is the state
    const zipCode = cityStateZip[1]; // Last part is the zip code
    setFormValues({
      ...formValues,
      propertyName: property.name || "",
      address1: parts[0] || "",
      city: city || "",
      state: state || "",
      postalCode: zipCode || "",
      propertyType: property.types.join(", ") || "", // Join types for better readability
      // Add other relevant fields as needed
    });
    setPropertyCheck(true);
    const url = URL.createObjectURL(file);
    setImageURL(url);
    setPostThumNailImage(file);
    setSearchResults([]); // Clear search results after selection
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 1) {
      fetchProperties(query); // Fetch properties on each input change
    }
  };
  const fetchProperties = async (query) => {
    if (!query) return; // Avoid empty search

    try {
      const response = await getPropertyFromGoogle(query);

      if (response.status === 200) {
        setSearchResults(response.data.results);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const [file, setFile] = useState(null);
  const getPhotoUrl = (property) => {
    if (property.photos && property.photos.length > 0) {
      const photoReference = property.photos[0].photo_reference;
      const photoUrl = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${photoReference}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
      createFileFromUrl(photoUrl); // Fetch and create the file object
      return photoUrl;
    }
    return null; // Return null or a default image URL if no photos are available
  };

  const createFileFromUrl = async (url) => {
    try {
      const response = await fetch(url); // Fetch the image data
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob(); // Convert response to Blob
      const fileName = "property-image.jpg"; // Set your desired file name
      const fileObject = new File([blob], fileName, { type: blob.type });
      setFile(fileObject); // Set the file object in state
    } catch (error) {}
  };

  return (
    <div className="side-nav">
      <div className="side-header">
        <h3>Add New Property</h3>
      </div>
      {propertyCheck ? (
        <div className="side-body">
          <Form>
            <Row className="mb-3">
              {/* Property Name */}
              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>Property Name <span className="requiredStar">*</span></Form.Label>
                <Form.Control
                  type="text"
                  value={formValues.propertyName}
                  name="propertyName"
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              {/* Property Image */}
              <Form.Group md="12" className="mb-3">
                <div className="files-choosen">
                  <div className="title">Property Image</div>
                  <div className="button">
                    <div className="outline-yellow-button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                          stroke="#B78C4E"
                          strokeLinecap="round"
                        />
                      </svg>
                      Add Property Image
                      <input
                        type="file"
                        className="chooeseFile"
                        onChange={handleImageChange}
                        accept=".png, .jpeg, .jpg, .webp"
                      />
                    </div>
                  </div>
                </div>
                <div className="listOfImages">
                  {imageURL && (
                    <div className="image-choosed">
                      <img src={imageURL} alt="Selected property" />
                      <button
                        onClick={handleRemoveImage}
                        className="image-choosed-crossed"
                      >
                        &times;
                      </button>
                    </div>
                  )}
                </div>
              </Form.Group>

              {/* Files */}
              <Form.Group md="12" className="mb-3">
                <div className="files-choosen">
                  <div className="title">Files</div>
                  <div className="button">
                    <div className="outline-yellow-button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                          stroke="#B78C4E"
                          strokeLinecap="round"
                        />
                      </svg>
                      Add file
                      <input
                        type="file"
                        className="chooeseFile"
                        multiple
                        accept=".png, .jpeg, .jpg, .webp, .pdf"
                        onChange={handleImagesChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="listOfImages">
                  {images.map((file) => (
                    <div className="image-choosed" key={file.id}>
                      {file.type === "application/pdf" ? (
                        <img src={pdfIcon} alt="PDF Icon" />
                      ) : (
                        <img src={file.url} alt="Selected" />
                      )}
                      <button
                        onClick={() => removeImage(file.id)}
                        className="image-choosed-crossed"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </Form.Group>

              {/* Address Fields */}
              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>
                  Address 1 <span className="requiredStar">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="123 Main Street"
                  value={formValues.address1}
                  name="address1"
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="such as apartment number, suite, floor, etc."
                  name="address2"
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>
                  City <span className="requiredStar">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="AnyTown"
                  value={formValues.city}
                  name="city"
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>
                  State <span className="requiredStar">*</span>
                </Form.Label>
                <Form.Select
                  value={formValues.state}
                  name="state"
                  onChange={handleInputChange}
                >
                  {states.map((state) => (
                    <option key={state.term_id} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>
                  Postal Code <span className="requiredStar">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={formValues.postalCode}
                  placeholder="12345"
                  name="postalCode"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Property Type */}
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Property Type</Form.Label>
                <Form.Select name="propertyType" onChange={handleInputChange}>
                  {propertyTypes.map((type) => (
                    <option key={type.term_id} value={type.term_id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {/* Property Category */}
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Property Category</Form.Label>
                <Form.Select
                  name="propertyCategory"
                  onChange={handleInputChange}
                >
                  {propertyCategories.map((category) => (
                    <option key={category.term_id} value={category.term_id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {/* Notes */}
              <Form.Group as={Col} md="12" className="mb-3">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Type something..."
                  name="notes"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Row>
          </Form>
        </div>
      ) : (
        <div className="side-body">
          <Form>
            <div className="create-reminder-parent">
              <InputGroup>
                <InputGroup.Text>
                  <img src={MagnifyingGlass} alt="search" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search for properties..."
                />
              </InputGroup>
              <div
                className="button uploadFile"
                onClick={() => handlerCustomProperty()}
              >
                <div className="outline-button-upload createReminders">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.00065 1.33325V10.6666M1.33398 5.99992H10.6673"
                      stroke="#00487C"
                      strokeLinecap="round"
                    />
                  </svg>
                  Custom Property
                </div>
              </div>
            </div>

            {searchResults.length > 0 && (
              <div className="property-list">
                {searchResults.map((property) => (
                  <div className="property-item" key={property.place_id}>
                    <img
                      src={
                        property.photos
                          ? getPhotoUrl(property)
                          : placeholderImage
                      }
                      alt={property.name}
                    />
                    <div className="property-details">
                      <p>
                        <strong>Address:</strong> {property.formatted_address}
                      </p>
                      <p>
                        <strong>Type:</strong> {property.types.join(", ")}
                      </p>
                      <button
                        className="footer-button-primary"
                        onClick={() => handleSelectProperty(property)}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};

export default PropertyNewSideNav;

import React from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg'
import copyIcon from '../assets/icons/copyIcon.svg'
import deActiveIcon from '../assets/icons/deActiveIcon.svg'
import ActiveIcon from '../assets/icons/ActiveIcon.svg'
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { updateReralCode } from '../../axiosCalls/seller';


const ShowCodeHistory = ({ referData,refreshData  }) => {
    // Function to copy referral code to clipboard
    const handleCopy = (code) => {
        navigator.clipboard.writeText(code)
            .then(() => {
                toast.success('Referral code copied to clipboard!');
            })
            .catch((error) => {
                console.error('Failed to copy referral code: ', error);
            });
    };
    const handlerStatusUpdate = async (id,oldStatus) => {
        const data  = {
            status:oldStatus=='active'?'inactive':'active'
        }

        const res = await updateReralCode(id,data);
        if (res.status === 200) {
            toast.success(res.data.message);
            refreshData(); // Refresh data after successful update
        }

    }
    const columns = [
        {
            name: "Referral Code",
            selector: (row) => row.ClientName,
            sortable: false,
            reorder: true,
        },
        {
            name: "Status",
            selector: (row) => row.ReferralCode,
            sortable: false,
            reorder: true,
        },
        {
            name: "Date Created",
            selector: (row) => row.RenewalDate,
            sortable: false,
            reorder: true,
        },

        {
            name: "Action",
            selector: (row) => row.Action,
            sortable: false,
            reorder: true,
        }


    ];
    const data = referData.map((item) => ({
        item_id: item.id,
        id: 1,
        ClientName: <div className='fileName'>{item.referral_code}</div>,
        ReferralCode: (
            <div className={`fileName ReferralStatus type-${item.status === 'active' ? 'green' : 'blue'}`}>
                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
            </div>
        ),
        RenewalDate: <div className='fileName'>{new Date(item.created_date).toLocaleDateString()}</div>,
        Action: (
            <div className='actions'>
                <span onClick={() => handlerStatusUpdate(item.id,item.status)}>
                    <img src={item.status === 'active' ? ActiveIcon : deActiveIcon} alt="status icon" />
                </span>
                <span onClick={() => handleCopy(item.referral_code)} style={{ cursor: 'pointer' }}>

                    <img src={copyIcon} alt="copy icon" />
                </span>
            </div>
        )
    }));

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={"300px"}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />

        </div>
    );
}

export default ShowCodeHistory;

import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

function ConfirmationModal({ show, handleClose, hanndleDelete, loading }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="mt-2 ms-2">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete the Property?</Modal.Body>
        <Modal.Footer>
          <button className="footer-button-cancel" onClick={handleClose}>
            Cancel
          </button>
          <button className="footer-button-primary " onClick={hanndleDelete}>
            {loading ? <Spinner size="sm" /> : "Delete"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;

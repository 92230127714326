import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Row, Col } from 'react-bootstrap';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import LinkReminders from './linkReminders';
import { get_home_essential_types, getHomeEsstentialList, updateHomeEssential } from '../../../axiosCalls/userdashboard';

const HomeEssentialsSideNavEdit = ({ homeEssentialId, updatedFormData }) => {
    const [homeEssentialTypes, setHomeEssentialTypes] = useState([]);
    const [formData, setFormData] = useState({});
    const [listOfImages, setListOfImages] = useState([]); // Unified list for files and images
    const [listOfFiles, setListOfFiles] = useState([]); // Unified list for files and images
    const [listOfFilesWarranty, setListOfFilesWarranty] = useState([]); // Unified list for files and images

    useEffect(() => {
        const fetchHomeEssentialTypes = async () => {
            try {
                const response = await get_home_essential_types();
                setHomeEssentialTypes(response.data);
            } catch (error) {
                console.error('Error fetching home essential types:', error);
            }
        };

        fetchHomeEssentialTypes();
    }, []);

    // Callback function to get reminders from the child component
    const handleRemindersUpdate = (reminders) => {

       setFormData((prevData) => ({ ...prevData, 'linked_reminders': reminders }));
    };

    // Fetch home essential data on component mount
    useEffect(() => {
        const fetchHomeEssential = async () => {
            try {
                const response = await getHomeEsstentialList(homeEssentialId);
                if (response && response.data) {
                    setFormData(response.data);
                    setListOfImages(response.data.images || []);
                    setListOfFiles(response.data.files || []);
                    setListOfFilesWarranty(response.data.warranty_data.files || []);
                    updatedFormData(response.data); // Send data back to the parent
                }
            } catch (error) {
                console.error("Error fetching home essential data:", error);
            }
        };

        fetchHomeEssential();
    }, [homeEssentialId]);

    useEffect(() => {
        updatedFormData(formData); // Update parent component whenever formData changes
    }, [formData]);

      // Handle nested form data change
      const handleChange = (field, value) => {
        if (field.includes('warranty_data')) {
            const index = parseInt(field.match(/\d+/)[0], 10); // Extract the index from 'warranty_data[x]'
            setFormData((prevData) => {
                 const updatedWarrantyData = prevData.warranty_data;
                updatedWarrantyData[index] = { ...updatedWarrantyData[index], ...value }; // Update specific index
                return { ...prevData, warranty_data: updatedWarrantyData }; // Update formData with modified warranty_data
            });
        } else {
            setFormData((prevData) => ({ ...prevData, [field]: value }));
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const updatedList = [...listOfFiles, ...selectedFiles];

        setListOfFiles(updatedList);
        handleChange('files', updatedList);
    };

    // Handle image input changes
    const handleImageChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        const updatedList = [...listOfImages, ...selectedImages];
        setListOfImages(updatedList);
        // Also update formData for images
        handleChange('images', updatedList);
    };

    // Handle image input changes
    const handleWarranyFileChange = (e) => {
        const selectedImages = Array.from(e.target.files);
        const updatedList = [...listOfFilesWarranty, ...selectedImages];
        setListOfFilesWarranty(updatedList);
        // Also update formData for images
        handleChange('warrantyFiles', updatedList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic (if needed)
    };

    // Function to remove existing files (image or PDF) by id
    const removeFile = (index) => {
        const updatedList = listOfFiles.filter((_, i) => i !== index);
        handleChange('files', updatedList);
        setListOfFiles(updatedList);
    };
    // Function to remove existing files (image or PDF) by id
    const removeFilesWarranty = (index) => {
        const updatedList = listOfFilesWarranty.filter((_, i) => i !== index);
        handleChange('warrantyFiles', updatedList);
         setListOfFilesWarranty(updatedList);
         setFormData((prevData) => {
            const updatedWarrantyData = prevData.warranty_data;
           updatedWarrantyData['files'] = updatedList ; // Update specific index
            return { ...prevData, warranty_data: updatedWarrantyData }; // Update formData with modified warranty_data
       });
    };

    // Function to remove newly added files or images (not yet uploaded)
    const removeImage = (index) => {
        const updatedList = listOfImages.filter((_, i) => i !== index);
        setListOfImages(updatedList);
        handleChange('images', updatedList); // Update formData images
    };

    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>Edit Home Essential</h3>
            </div>
            <div className='side-body'>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Home Essential Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                value={formData.name || ''}
                                onChange={(e) => handleChange('name', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6">
                            <Form.Label>Type <span className='requiredStar'>*</span></Form.Label>
                            <Form.Select
                                value={formData.type_id || ''}
                                onChange={(e) => handleChange('type_id', e.target.value)}
                            >
                                <option value=''>Select Type</option>
                                {homeEssentialTypes.map((type) => (
                                    <option key={type.id} value={type.id}>
                                        {type.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Make </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="e.g. 2012"
                                value={formData.make || ''}
                                onChange={(e) => handleChange('make', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Model Number </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="e.g. 4689-65498-4646"
                                value={formData.model_number || ''}
                                onChange={(e) => handleChange('model_number', e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Purchase Date <span className='requiredStar'>*</span></Form.Label>
                            <Form.Control
                                type="date"
                                value={formData.purchase_date || ''}
                                onChange={(e) => handleChange('purchase_date', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Label>Age of Home Essential</Form.Label>
                            <Form.Control
                                type="date"
                                value={formData.age_of_home_essential || ''}
                                onChange={(e) => handleChange('age_of_home_essential', e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group md="12" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Linked Reminders</Form.Label>
                              {
                                formData.linked_reminders ?   <LinkReminders type="homeEssential" onRemindersUpdate={handleRemindersUpdate} /> : ''}
                            </div>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Files</div>
                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        Add file
                                        <input
                                            type='file'
                                            className='chooeseFile'
                                            accept=".pdf"
                                            multiple
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Display uploaded files */}
                            <div className='listOfImages'>
                                {listOfFiles?.map((img, index) => (
                                    <div className='image-choosed' key={index}>
                                        <a href={typeof img === 'string' ? img : URL.createObjectURL(img)} target='_blank' rel='noopener noreferrer'>
                                            <img src={pdfIcon} alt='Image file' />
                                        </a>
                                        <button
                                            onClick={() => removeFile(index)}
                                            className='image-choosed-crossed'
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen'>
                                <div className='title'>Images</div>
                                <div className='button'>
                                    <div className="outline-yellow-button">
                                        Add image
                                        <input
                                            type='file'
                                            className='chooeseFile'
                                            accept=".png, .jpeg, .jpg, .webp"
                                            multiple
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Display images */}
                            <div className='listOfImages'>
                                {listOfImages?.map((img, index) => (
                                    <div className='image-choosed' key={index}>
                                        <a href={typeof img === 'string' ? img : URL.createObjectURL(img)} target='_blank' rel='noopener noreferrer'>
                                            <img src={typeof img === 'string' ? img : URL.createObjectURL(img)} alt='Image file' />
                                        </a>
                                        <button
                                            onClick={() => removeImage(index)}
                                            className='image-choosed-crossed'
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder='Type here...'
                                value={formData.notes || ''}
                                onChange={(e) => handleChange('notes', e.target.value)}
                            />
                        </Form.Group>

                        {/* Warranty Section */}
                        <Form.Group md="12" className='mb-3'>
                            <div className='files-choosen warrantyAddArea'>
                                <div className='title'>Warranty</div>
                                <div className='button'>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={!!formData.warranty}
                                            onChange={() => handleChange('warranty', formData.warranty ? false : true)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className='warrantyForm'>
                                {formData.warranty && (
                                    <div className='warrantyAddInnerArea'>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="12" className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Name"
                                                    value={formData.warranty_data[0]?.name || ''}
                                                    onChange={(e) => handleChange('warranty_data[0]', { ...formData.warranty_data[0], name: e.target.value })}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="12" className="mb-3">
                                                <Form.Label>Number</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Number"
                                                    value={formData.warranty_data[1]?.number || ''}
                                                    onChange={(e) => handleChange('warranty_data[1]', { ...formData.warranty_data[1], number: e.target.value })}
                                                />
                                            </Form.Group>
                                            <Form.Group md="12" className='mb-3'>
                                                <div className='files-choosen'>
                                                    <div className='title'>Files</div>
                                                    <div className='button'>
                                                        <div className="outline-yellow-button">
                                                            Add file
                                                            <input
                                                                type='file'
                                                                className='chooeseFile'
                                                                accept=".pdf"
                                                                multiple
                                                                onChange={handleWarranyFileChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Display images */}
                                                <div className='listOfImages'>
                                                    {listOfFilesWarranty?.map((img, index) => (
                                                        <div className='image-choosed' key={index}>
                                                            <a href={typeof img === 'string' ? img : URL.createObjectURL(img)} target='_blank' rel='noopener noreferrer'>
                                                                <img src={pdfIcon} alt='Image file' />
                                                            </a>
                                                            <button
                                                                onClick={() => removeFilesWarranty(index)}
                                                                className='image-choosed-crossed'
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>

                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>Start</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={formData.warranty_data[2]?.start_date || ''}
                                                    onChange={(e) => handleChange('warranty_data[2]', { ...formData.warranty_data[2], start_date: e.target.value })}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className="mb-3">
                                                <Form.Label>End</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={formData.warranty_data[3]?.end_date || ''}
                                                    onChange={(e) => handleChange('warranty_data[3]', { ...formData.warranty_data[3], end_date: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Form.Group as={Col} className="mb-3">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder='Type here...'
                                                value={formData.warranty_data[4]?.notes || ''}
                                                onChange={(e) => handleChange('warranty_data[4]', { ...formData.warranty_data[4], notes: e.target.value })}
                                            />
                                        </Form.Group>
                                    </div>
                                )}
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default HomeEssentialsSideNavEdit;

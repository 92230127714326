import React, { useEffect, useState } from "react";

import "../asstes/styles/dashboardStyles.css";
import { Link } from "react-router-dom";
import warningIcon from "../asstes/dash-icons/warningIcon.png";
import icon1 from "../asstes/dash-icons/sidebar/dashIcon1.svg";
import icon2 from "../asstes/dash-icons/sidebar/dashIcon2.svg";
import icon3 from "../asstes/dash-icons/sidebar/dashIcon3.svg";
import icon4 from "../asstes/dash-icons/sidebar/dashIcon4.svg";
import icon5 from "../asstes/dash-icons/sidebar/dashIcon5.svg";
import DashboardCalender from "../components/inc/dashboardCalender";
import RecentPurchases from "../components/inc/recentPurchases";
import RecentFiles from "../components/inc/recentFiles";
import ProjectsSideNav from "../components/inc/projectsSideNav";
import ProjectsSideNavNew from "../components/inc/projectsSideNavNew";
import ProjectSideNavEdit from "../components/inc/projectSideNavEdit";
import HomeEssentialsSideNav from "../components/inc/homeEssentialsSideNav";
import ReminderSideNavCreate from "../components/inc/ReminderSideNavCreate";
import PurchasesServicesNavAdd from "../components/inc/purchasesServicesNavAdd";
import FilesSideNavAdd from "../components/inc/filesSideNavAdd";
import HomeEssentialsSideNavView from "../components/inc/homeEssentialsSideNavView";
import HomeEssentialsSideNavEdit from "../components/inc/homeEssentialsSideNavEdit";
import ReminderSideNav from "../components/inc/reminderSideNav";
import {
  addCustomReminder,
  addFileApis,
  addHomeEssential,
  addNotificationData,
  addProjectApis,
  AddPurchaseItem,
  AddServiceItem,
  getHomeEsstentialList,
  getProjectsList,
  getTaxonomyById,
  updateHomeEssential,
  updateProjectApis,
} from "../../axiosCalls/userdashboard";
import { toast } from "react-toastify";
import { isPropertyExist } from "../utils/helper";
import AddHomeEssential from "../components/add_home_essential/addHomeEssential";
import { driver } from "driver.js";
import { entireDriver } from "./driver/constant";
import QuickAction from "../../quick-action/QuickAction";
import AddlineItem from "../components/inc/addlineItem";
const Dashboard = () => {
  const MainDriver = driver({
    onCloseClick: () => {
      localStorage.setItem("mainTour", JSON.stringify(true));
      MainDriver.destroy();
    },
    ...entireDriver,
  });
  const [sideNavWidth, setSideNavWidth] = useState("sidenavPopuWidthHide");
  const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState("0%");
  const [continueChange, setContinueChange] = useState("createStep");
  const [valueForView, setValueForView] = useState([]);
  const [homeEssentialViewId, setHomeEssentialViewId] = useState([]);
  const [NewFileData, setNewFileData] = useState([]);
  const [openModaLineItem, setOpenModaLineItem] = useState(false);
  const [prodLineItem, setProductLineItems] = useState([]);
  const [servLineItem, setServiceLineItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState("purchase");
  const [updateProdItem, setUpdateProItem] = useState(null);
  const [quickAddDetail, setQuickAddDetail] = useState(null);
  const [listProject, setListProjects] = useState([]);
  const [ViewValueForProject, setViewValueForProject] = useState([]);
  const [updatedProjectFormData, setUpdatedProjectFormData] = useState([]);
  const [updatedHomeEssentialsData, setUpdatedHomeEssentialsData] = useState(
    []
  );
  const [homeEssentials, setHomeEssentials] = useState([]);
  const [homeEsstentialformData, setHomeEsstentialFormData] = useState({
    homeEssentialName: "",
    make: "",
    modelNumber: "",
    purchaseDate: "",
    files: [],
    warrantyFiles: [],
    images: [],
    notes: "",
    warrantyData: {
      name: "",
      number: "",
      startDate: "",
      endDate: "",
      notes: "",
      files: [],
    },
    linkedReminders: [],
    selectedType: "",
    warrantyCheck: "unchecked",
  });
  const handlerCancelSideNav = () => {
    setSideNavWidth("sidenavPopuWidthHide");
    setsideNavWidthOverlay("0%");
    document.body.classList.remove("no-scroll");
  };
  const handlerChangeStep = async () => {
    if (continueChange === "viewStepHome") {
      setContinueChange("EditStepHome");
    } else if (continueChange === "suggets") {
      setContinueChange("EditStep");
    }

    if (continueChange === "createStepFiles") {
      if (!NewFileData.fileName || !NewFileData.uploadDate) {
        toast.error("Please fill out required fields.");
        return;
      }
      if (!NewFileData.files?.length) {
        toast.error("File is required fields.");
        return;
      }
      try {
        let data = new FormData();
        data.append("file_name", NewFileData.fileName);
        data.append("upload_date", NewFileData.uploadDate);

        //  data.append('related_to', '3');
        if (NewFileData.files) {
          NewFileData.files.forEach((file) => data.append("files[]", file));
        }
        if (NewFileData.reminderIds) {
          NewFileData.reminderIds.forEach((remind) =>
            data.append("reminder_ids[]", remind)
          );
        }

        const res = await addFileApis(data);
        if (res && res.status) {
          toast.success(res.data.message);
          handlerCancelSideNav();
        }
      } catch (error) {
        console.log("ERROR::", error);
      }
    }
    if (continueChange === "EditStepHome") {
      handleUpdateHomeEssential();
    }
    if (continueChange == "createStepHome") {
      handleSubmitHomeEssentials();
    }
    if (continueChange === "EditStep") {
      handleUpdateProject();
    }
    if (continueChange === "createStepProject") {
      handleSubmitProject();
    }
    if (continueChange === "createStepPServices") {
      if (serviceData.serviceName) {
        handlerAddService();
      }
      if (purchaseData.productName) {
        handlerAddPurchase();
      }
    }
    if (
      continueChange === "createStepRemind" ||
      continueChange === "createStepCustomReminder"
    ) {
      submitReminder();
    }
  };

  const handlerHomeEssentialSideNave = () => {
    if (isPropertyExist()) {
      setContinueChange("createStepHome");
      setSideNavWidth("sidenavPopuWidth");
      setsideNavWidthOverlay("100%");
      document.body.classList.add("no-scroll");
    }
  };

  const handlerProjectsSideNave = () => {
    setContinueChange("createStepProject");
    setSideNavWidth("sidenavPopuWidth");
    setsideNavWidthOverlay("100%");
    document.body.classList.add("no-scroll");
  };
  const handlerRemindersSideNave = () => {
    setContinueChange("createStepRemind");
    setSideNavWidth("sidenavPopuWidth");
    setsideNavWidthOverlay("100%");
    document.body.classList.add("no-scroll");
  };
  const handlerPServicesSideNave = (activeItem, detail) => {
    setContinueChange("createStepPServices");
    setIsChecked(false);
    setSideNavWidth("sidenavPopuWidth");
    setsideNavWidthOverlay("100%");
    document.body.classList.add("no-scroll");
    if (detail) {
      if (detail?.lineItems?.length) {
        let list = detail?.lineItems.map((ii) => {
          return {
            name: ii?.Type,
            price: ii?.PRICE,
          };
        });
        setProductLineItems(list);
      }
      setQuickAddDetail(detail?.info);
    }
  };
  const handlerFilesSideNave = () => {
    setContinueChange("createStepFiles");
    setSideNavWidth("sidenavPopuWidth");
    setsideNavWidthOverlay("100%");
    document.body.classList.add("no-scroll");
  };

  function HandlerViewHomeEsstional(id, title) {
    setHomeEssentialViewId(title);
    setContinueChange("viewStepHome");
    setSideNavWidth("sidenavPopuWidth");
    setsideNavWidthOverlay("100%");
    document.body.classList.add("no-scroll");
  }
  const [isChecked, setIsChecked] = useState(false); // State to hold checkbox value

  const handleCheckboxChangeOLD = (isChecked) => {
    setIsChecked(isChecked);
  };
  useEffect(() => {
    const isMainTour = JSON.parse(localStorage.getItem("mainTour"));
    const user = JSON.parse(localStorage.getItem("user"));
    if (!isMainTour && !user.meta_data.mainTour ) {
      MainDriver.drive();
    }
  }, []);

  // Home Essentials

  const updatedFormData = (data) => {
    setUpdatedHomeEssentialsData(data);
  };

  const updateFormData = (updatedData) => {
    setHomeEsstentialFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };
  // Fetch home essentials when the component is mounted
  useEffect(() => {
    const fetchHomeEssentials = async () => {
      try {
        const response = await getHomeEsstentialList();
        if (response && response.data && response.data.items) {
          setHomeEssentials(response.data.items);
        } else {
          console.error("No home essential data found");
        }
      } catch (error) {
        console.error("Error fetching home essentials:", error);
      }
    };

    fetchHomeEssentials();
  }, []);
  const handleSubmitHomeEssentials = async () => {
    const {
      homeEssentialName,
      make,
      modelNumber,
      purchaseDate,
      ageofEssential,
      linkedReminders,
      notes,
      selectedType,
      warrantyCheck,
      warrantyData,
      files,
      images,
      warrantyFiles,
    } = homeEsstentialformData;

    if (!selectedType) {
      toast.error("Please select a Home Essential Type");
      return;
    }

    if (!purchaseDate) {
      toast.error("Purchase Date is required");
      return;
    }

    let data = new FormData();
    data.append("type", selectedType);
    if (homeEssentialName) data.append("name", homeEssentialName);
    if (make) data.append("make", make);
    if (modelNumber) data.append("model_number", modelNumber);
    if (ageofEssential) data.append("age_of_home_essential", ageofEssential);
    data.append("purchase_date", purchaseDate);
    linkedReminders.forEach((remind) => {
      const reminderData = JSON.stringify({
        id: remind.id,
        date: remind.start_date,
      });
      data.append("linked_reminders[]", reminderData);
    });
    if (notes) data.append("notes", notes);
    data.append("warranty", warrantyCheck === "checked" ? "true" : "false");

    if (warrantyCheck === "checked") {
      if (warrantyData.name)
        data.append("warranty_data[][name]", warrantyData.name);
      if (warrantyData.number)
        data.append("warranty_data[][number]", warrantyData.number);
      if (warrantyData.startDate)
        data.append("warranty_data[][start_date]", warrantyData.startDate);
      if (warrantyData.endDate)
        data.append("warranty_data[][end_date]", warrantyData.endDate);
      if (warrantyData.notes)
        data.append("warranty_data[][notes]", warrantyData.notes);
      if (warrantyFiles.length)
        warrantyFiles.forEach((file) =>
          data.append("warranty_data_files[]", file)
        );
    }
    if (files.length) files.forEach((file) => data.append("files[]", file));
    if (images.length)
      images.forEach((image) => data.append("images[]", image));
    try {
      const response = await addHomeEssential(data);
      if (response.data.post_id) {
        toast.success(response.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.error("Error submitting home essential:", error);
    }
  };
  const handleUpdateHomeEssential = async () => {
    const {
      name,
      model_number,
      make,
      purchase_date,
      age_of_home_essential,
      linked_reminders,
      notes,
      type,
      type_id,
      warranty,
      warranty_data,
      files,
      images,
      warrantyFiles,
    } = updatedHomeEssentialsData;

    if (!type_id) {
      toast.error("Please select a Home Essential Type");
      return;
    }
    if (!purchase_date) {
      toast.error("Purchase Date is required");
      return;
    }

    let data = new FormData();
    data.append("type", type_id);
    if (name) data.append("name", name);
    if (make) data.append("make", make);
    if (model_number) data.append("model_number", model_number);
    if (age_of_home_essential)
      data.append("age_of_home_essential", age_of_home_essential);
    data.append("purchase_date", purchase_date);
    linked_reminders.forEach((remind) => {
      const reminderData = JSON.stringify({
        id: remind.id,
        date: remind.start_date,
      });
      data.append("linked_reminders[]", reminderData);
    });
    if (notes) data.append("notes", notes);
    if (warranty) data.append("warranty", warranty);

    if (warranty) {
      if (warranty_data[0].name)
        data.append("warranty_data[][name]", warranty_data[0].name);
      if (warranty_data[1].number)
        data.append("warranty_data[][number]", warranty_data[1].number);
      if (warranty_data[2].start_date)
        data.append("warranty_data[][start_date]", warranty_data[2].start_date);
      if (warranty_data[3].end_date)
        data.append("warranty_data[][end_date]", warranty_data[3].end_date);
      if (warranty_data[4].notes)
        data.append("warranty_data[][notes]", warranty_data[4].notes);
      if (warranty_data.files)
        data.append("warranty_data[files]", warranty_data.files);
      if (warrantyFiles) {
        warrantyFiles.forEach((file) =>
          data.append("warranty_data_files[]", file)
        );
      }
    }
    if (files?.length) files.forEach((file) => data.append("files[]", file));
    if (images?.length)
      images.forEach((image) => data.append("images[]", image));
    if (warranty_data.files?.length)
      warranty_data.files.forEach((image) =>
        data.append("warranty_data[files][]", image)
      );

    try {
      const response = await updateHomeEssential(
        data,
        updatedHomeEssentialsData.id
      );
      if (response.status == 200) {
        toast.success(response.data.message);
        handlerCancelSideNav();
      }
      // Optionally, trigger an update to the parent component or redirect
    } catch (error) {
      console.error("Error updating home essential:", error);
    }
  };

  // reminders
  const [backButton, setBackButton] = useState(false);
  const [reminderData, setReminderData] = useState([]);

  const buttonShowingBackWith = (data) => {
    setBackButton(data);
  };
  const suggestedReminderAdd = (data) => {
    setReminderData(data);
  };
  const submitReminder = async () => {
    try {
      const responseCustomReminder = await addCustomReminder(reminderData);
      const response = await addNotificationData(
        reminderData,
        responseCustomReminder.data.post_id
      );
      if (response && response.data) {
        if (response.data.status == 400) {
          toast.error(response.message);
        } else {
          toast.success(response.data.message);
          handlerCancelSideNav();
        }
      }
    } catch (error) {}
  };
  const handlerCustomReminder = (isChecked) => {
    setContinueChange("createStepCustomReminder");
  };
  // Project data

  const updatedProjectData = (data) => {
    setUpdatedProjectFormData(data);
  };
  useEffect(() => {
    const handleGetProjectList = async () => {
      const res = await getProjectsList();
      if (res && res.data) {
        setListProjects(res.data); // Set the projects list to the state
      }
    };
    handleGetProjectList();
  }, []);

  const HandlerViewProject = (pro) => {
    setViewValueForProject(pro);
    setContinueChange("suggets");

    setSideNavWidth("sidenavPopuWidth");
    setsideNavWidthOverlay("100%");
    document.body.classList.add("no-scroll");
  };

  // Function to handle submitting a project

  const handleSubmitProject = async () => {
    // Validation
    const {
      project_name,
      start_date,
      end_date,
      notes,
      linked_home_essentials,
      linked_services,
      linked_purchases,
      files,
      images,
    } = updatedProjectFormData;
    // Check for required fields
    if (!project_name) {
      toast.error("Project Name is required");
      return;
    }
    if (!start_date) {
      toast.error("Start Date is required");
      return;
    }

    try {
      let data = new FormData();
      data.append("project_name", project_name);
      data.append("start_date", start_date);
      data.append("end_date", end_date);
      data.append("notes", notes);
      data.append(
        "linked_home_essentials",
        JSON.stringify(linked_home_essentials)
      );
      data.append("linked_services", JSON.stringify(linked_services));
      data.append("linked_purchases", JSON.stringify(linked_purchases));

      files.forEach((file) => data.append("files[]", file));
      images.forEach((image) => data.append("images[]", image));

      const res = await addProjectApis(data);
      if (res && res.status === 200) {
        toast.success(res.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.error("Error submitting project:", error);
      toast.error(
        "There was an error submitting the project. Please try again."
      );
    }
  };

  // Function to handle updating a project
  const handleUpdateProject = async () => {
    // Validation
    const {
      project_name,
      start_date,
      end_date,
      notes,
      linked_home_essentials,
      linked_services,
      linked_purchases,
      files,
      images,
    } = updatedProjectFormData;

    // Check for required fields
    if (!project_name) {
      toast.error("Project Name is required");
      return;
    }
    if (!start_date) {
      toast.error("Start Date is required");
      return;
    }

    try {
      let data = new FormData();
      data.append("project_name", project_name);
      data.append("start_date", start_date);
      data.append("end_date", end_date);
      data.append("notes", notes);
      data.append(
        "linked_home_essentials",
        JSON.stringify(linked_home_essentials)
      );
      data.append("linked_services", JSON.stringify(linked_services));
      data.append("linked_purchases", JSON.stringify(linked_purchases));

      files.forEach((file) => data.append("files[]", file));
      images.forEach((image) => data.append("images[]", image));

      const res = await updateProjectApis(data, valueForView.ID);
      if (res && res.status === 200) {
        toast.success(res.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.error("Error updating project:", error);
      toast.error("There was an error updating the project. Please try again.");
    }
  };

  ///
  //  Purchase and dervice Data
  const [purchaseData, setPurchaseData] = useState({});
  const [serviceData, setServiceData] = useState({});

  const updatePurchaseData = (data) => {
    setPurchaseData(data);
  };
  const updatedServiceData = (data) => {
    setServiceData(data);
  };
  const validateServiceData = (data) => {
    const { serviceName, amount, serviceDate, vendor } = data;

    // Simple checks to ensure required fields are filled
    if (!serviceName) {
      toast.error("Service name is required.");
      return false;
    }
    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Amount must be a positive number.");
      return false;
    }
    if (!serviceDate) {
      toast.error("Service date is required.");
      return false;
    }
    if (!vendor) {
      toast.error("Vendor is required.");
      return false;
    }

    return true;
  };

  const validatePurchaseData = (data) => {
    const { productName, amount, purchaseDate } = data;

    // Simple checks to ensure required fields are filled
    if (!productName) {
      toast.error("Product name is required.");
      return false;
    }
    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Amount must be a positive number.");
      return false;
    }
    if (!purchaseDate) {
      toast.error("Purchase date is required.");
      return false;
    }

    return true;
  };

  const handlerAddService = async () => {
    // Validate service data before proceeding
    if (!validateServiceData(serviceData)) return;

    const {
      serviceName,
      amount,
      files,
      project,
      linked_homeessential,
      linked_reminders,
      notes,
      serviceDate,
      vendor,
      warranty,
      warrantyEnd,
      warrantyName,
      warrantyNotes,
      warrantyNumber,
      warrantyStart,
    } = serviceData;

    let data = new FormData();
    data.append("service_name", serviceName);
    data.append("vendor", vendor);
    data.append("service_date", serviceDate);
    data.append("amount", amount);

    linked_reminders.forEach((remind) => {
      const reminderData = JSON.stringify({
        id: remind.id,
        date: remind.start_date,
      });
      data.append("linked_reminders[]", reminderData);
    });

    data.append("project", project);
    data.append("notes", notes);
    data.append("linked_homeessential", JSON.stringify(linked_homeessential));
    data.append("warranty", warranty);

    if (warranty) {
      data.append("warranty_data[][name]", warrantyName);
      data.append("warranty_data[][number]", warrantyNumber);
      data.append("warranty_data[][start_date]", warrantyStart);
      data.append("warranty_data[][end_date]", warrantyEnd);
      data.append("warranty_data[][notes]", warrantyNotes);

      if (files.receipt) {
        files.warrantyFiles.forEach((file) =>
          data.append("warranty_data_files[]", file)
        );
      }
    }

    if (files.receipt) {
      files.receipt.forEach((file) => data.append("receipt[]", file));
    }
    if (files.files) {
      files.files.forEach((file) => data.append("files[]", file));
    }

    try {
      const res = await AddServiceItem(data);
      if (res.status == 200) {
        toast.success(res.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handlerAddPurchase = async () => {
    // Validate purchase data before proceeding
    if (!validatePurchaseData(purchaseData)) return;

    const {
      amount,
      files,
      project,
      linked_homeessential,
      linked_reminders,
      make,
      modelNumber,
      notes,
      productName,
      purchaseDate,
      warranty,
      warrantyStart,
      warrantyEnd,
      warrantyName,
      warrantyNotes,
      warrantyNumber,
    } = purchaseData;

    let data = new FormData();
    data.append("product_name", productName);
    data.append("make", make);
    data.append("model_number", modelNumber);
    data.append("purchase_date", purchaseDate);
    data.append("amount", amount);

    linked_reminders.forEach((remind) => {
      const reminderData = JSON.stringify({
        id: remind.id,
        date: remind.start_date,
      });
      data.append("linked_reminders[]", reminderData);
    });

    data.append("linked_homeessential", JSON.stringify(linked_homeessential));
    data.append("notes", notes);
    data.append("project", project);
    data.append("warranty", warranty);

    if (warranty) {
      data.append("warranty_data[][name]", warrantyName);
      data.append("warranty_data[][number]", warrantyNumber);
      data.append("warranty_data[][start_date]", warrantyStart);
      data.append("warranty_data[][end_date]", warrantyEnd);
      data.append("warranty_data[][notes]", warrantyNotes);

      if (files.receipt) {
        files.warrantyFiles.forEach((file) =>
          data.append("warranty_data_files[]", file)
        );
      }
    }

    if (files.receipt) {
      files.receipt.forEach((file) => data.append("receipt[]", file));
    }
    if (files.files) {
      files.files.forEach((file) => data.append("files[]", file));
    }

    try {
      const res = await AddPurchaseItem(data);
      if (res.status == 200) {
        toast.success(res.data.message);
        handlerCancelSideNav();
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  ///
  const handlerCustomFile = (data) => {
    setNewFileData(data);
  };
  // property Data
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [propertyCategoryName, setPropertyCategoryName] = useState(null);
  const [propertyTypeName, setPropertyTypeName] = useState(null);
  const [propertyStateName, setPropertyStateName] = useState(null);

  useEffect(() => {
    const propertyData = localStorage.getItem("selectedProperty");
    if (propertyData) {
      const parsedData = JSON.parse(propertyData);
      setPropertyDetails(parsedData);
      fetchTaxonomyDetails(parsedData);
    }

    const handleStorageChange = () => {
      const updatedPropertyData = localStorage.getItem("selectedProperty");
      if (updatedPropertyData) {
        const parsedData = JSON.parse(updatedPropertyData);
        setPropertyDetails(parsedData);
        fetchTaxonomyDetails(parsedData);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  const fetchTaxonomyDetails = async ({ category, type, state }) => {
    try {
      const [categoryRes, typeRes, stateRes] = await Promise.all([
        getTaxonomyById("property-category", category),
        getTaxonomyById("property-type", type),
        getTaxonomyById("states", state),
      ]);

      setPropertyCategoryName(categoryRes.data.name);
      setPropertyTypeName(typeRes.data.name);
      setPropertyStateName(stateRes.data.name);
    } catch (error) {
      console.error("Error fetching taxonomy details:", error);
    }
  };
  const openAddProductModal = () => setOpenModaLineItem(true);
  const closeAddProdModal = () => setOpenModaLineItem(false);
  const removeLineItemFunc = (index = -1) => {
    if (selectedTab === "purchase") {
      let cloneProItem = [...prodLineItem];
      cloneProItem.splice(index, 1);
      setProductLineItems(cloneProItem);
    }
    if (selectedTab === "services") {
      let cloneServItem = [...servLineItem];
      cloneServItem.splice(index, 1);
      setServiceLineItems(cloneServItem);
    }
  };
  return (
    <>
      {/* <!-- partial --> */}

      <div className="content-wrapper">
        <div className="row">
          <div className="col-xl-12 grid-margin stretch-card flex-column">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 dashboardHeadings">
                <h5 className="mb-2 text-titlecase mb-4">
                  {propertyDetails && propertyDetails.title}
                </h5>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 d-flex text-top-subtext dashboardHeadings">
                <div className="col-md-6 top-text-head">
                  {propertyDetails && propertyDetails.address_1 &&(

                    'Address:'
                  )}
                  <span className="top-text-normal">
                    {propertyDetails &&
                      [
                        propertyDetails.address_1,
                        propertyDetails.address_2,
                        propertyDetails.city,
                        propertyStateName,
                        propertyDetails.postal_code,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                  </span>
                </div>
                {propertyCategoryName && (
                  <div className="col-md-3 top-text-head">
                    Category:
                    <span className="top-text-normal">
                      {propertyCategoryName}
                    </span>
                  </div>
                )}
                {propertyTypeName && (
                  <div className="col-md-3 top-text-head">
                    Type:
                    <span className="top-text-normal">{propertyTypeName}</span>
                  </div>
                )}
              </div>
            </div>

            {/* Home Essentials Board */}

            <div className="row  dashboard-card-parent">
              <div className="row card-actions-btns">
                <div className="col-md-6 col-sm-6">
                  <h2 className="card-heading">Home Essentials</h2>
                </div>
                <div className="col-md-6 col-sm-6 card-right-action">
                  <Link to="/home-essentials" className="outline-yellow-button">
                    View All
                  </Link>
                  <Link
                    onClick={handlerHomeEssentialSideNave}
                    className="outline-primary-button"
                  >
                    Add
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                        stroke="#00487C"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="row">
                {!homeEssentials.length ? (
                  <AddHomeEssential
                    heading={"Add home essential"}
                    onClick={handlerHomeEssentialSideNave}
                  />
                ) : null}
                {homeEssentials
                  .filter((_, index) => index <= 5) // Filter items with index <= 5
                  .map((card, index) => (
                    <div
                      className="col-lg-2 col-md-4 col-sm-6 item_each_home_cards"
                      id={index}
                      key={index}
                      onClick={(e) => HandlerViewHomeEsstional(index, card.id)}
                    >
                      <div className="dashboard-card">
                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                          <div className="justify-content-between align-items-center">
                            <div className="icon-box-card">
                              <img src={`${icon1}`} alt={card.model_number} />
                              {card.warranty && (
                                <div className="card-warining">
                                  <img alt="Warning" src={warningIcon} />
                                  <span>Warning</span>
                                </div>
                              )}
                            </div>
                            <p className="dashboard-heading">{card.name}</p>
                            <p className="dashboard-subheading">{card.type}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* caldender board  */}
            <div className="row  dashboard-card-parent">
              <div className="row card-actions-btns">
                <div className="col-md-6">
                  <h2 className="card-heading">Upcoming Reminders</h2>
                </div>
                <div className="col-md-6 card-right-action">
                  <Link to="/reminders" className="outline-yellow-button">
                    View All
                  </Link>
                  <Link
                    onClick={handlerRemindersSideNave}
                    className="outline-primary-button"
                  >
                    Add
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                        stroke="#00487C"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <DashboardCalender />
                </div>
              </div>
            </div>

            <div className="row  dashboard-card-parent">
              <div className="row card-actions-btns">
                <div className="col-md-6 col-sm-6">
                  <h2 className="card-heading">Projects</h2>
                </div>
                <div className="col-md-6 col-sm-6 card-right-action">
                  <Link to="/projects" className="outline-yellow-button">
                    View All
                  </Link>
                  <Link
                    onClick={handlerProjectsSideNave}
                    className="outline-primary-button"
                  >
                    Add
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                        stroke="#00487C"
                        strokeLinecap="round"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="row paddingRight0">
                {!listProject.length ? (
                  <AddHomeEssential
                    heading={"Add projects"}
                    onClick={handlerProjectsSideNave}
                  />
                ) : null}
                {listProject
                  .filter((_, index) => index < 4)
                  .map((pro, index) => (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 mb-3 item_each_home_cards"
                      id={index}
                      key={index}
                      onClick={() => HandlerViewProject(pro)}
                    >
                      <div className="dashboard-card">
                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                          <div className="justify-content-between align-items-center">
                            <div className="icon-box-card"></div>
                            <p className="projects dashboard-heading">
                              {pro.title}
                            </p>
                            <p className="dashboard-subheading row ProjectService">
                              {JSON.parse(pro.linked_home_essentials).map(
                                (ser, Childindex) => (
                                  <span
                                    className={
                                      "childProjectService services-badge type-red"
                                    }
                                    key={Childindex}
                                  >
                                    {ser.label}
                                  </span>
                                )
                              )}
                              {JSON.parse(pro.linked_services).map(
                                (ser, Childindex) => (
                                  <span
                                    className={
                                      "childProjectService services-badge type-camel"
                                    }
                                    key={Childindex}
                                  >
                                    {ser.label}
                                  </span>
                                )
                              )}
                              {JSON.parse(pro.linked_purchases).map(
                                (ser, Childindex) => (
                                  <span
                                    className={
                                      "childProjectService services-badge type-green"
                                    }
                                    key={Childindex}
                                  >
                                    {ser.label}
                                  </span>
                                )
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        Project Cost: ${pro.cost}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="row dashboard-parent-recents recent_files-action">
              {/* Recent Purchases */}
              <div className=" col-md-6 dashboard-card-parent recentPurchasesMobile">
                <div className="row card-actions-btns">
                  <div className="col-md-6">
                    <h2 className="card-heading">
                      Recent Purchases & Services
                    </h2>
                  </div>
                  <div className="col-md-6 card-right-action">
                    <Link
                      to="/purchases-services"
                      className="outline-yellow-button"
                    >
                      View All
                    </Link>
                    <Link
                      onClick={handlerPServicesSideNave}
                      className="outline-primary-button"
                    >
                      Add
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                          stroke="#00487C"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>

                <div className="row p-0">
                  <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                    <RecentPurchases />
                  </div>
                </div>
              </div>
              {/* Recent files */}
              <div className=" col-md-6 dashboard-card-parent recent_files-action">
                <div className="row card-actions-btns">
                  <div className="col-md-6">
                    <h2 className="card-heading">Recent Files</h2>
                  </div>
                  <div className="col-md-6 card-right-action">
                    <Link to="/files" className="outline-yellow-button">
                      View All
                    </Link>
                    <Link
                      onClick={handlerFilesSideNave}
                      className="outline-primary-button"
                    >
                      Add
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673"
                          stroke="#00487C"
                          strokeLinecap="round"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="row p-0">
                  <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                    <RecentFiles />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={handlerCancelSideNav}
          style={{ width: sideNavWidthOverlay }}
          className="sidenav-overlay"
        ></div>
        {isChecked ? (
          <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
            <HomeEssentialsSideNav
              formData={homeEsstentialformData}
              updateFormData={updatedFormData}
            />

            <div className="side-footer">
              <button
                className="footer-button-cancel"
                onClick={handlerCancelSideNav}
              >
                Cancel
              </button>
              <button
                className="footer-button-primary "
                onClick={handlerChangeStep}
              >
                {continueChange.includes("Edit")
                  ? "Save"
                  : continueChange.includes("create")
                  ? "Save"
                  : "Edit"}
              </button>

              {continueChange.includes("Edit") ? (
                <button className="footer-button-delete footer-button-back">
                  Delete
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
            {continueChange === "suggets" ? (
              <ProjectsSideNav propsValue={ViewValueForProject} />
            ) : continueChange === "EditStep" ? (
              <ProjectSideNavEdit
                updatedData={updatedProjectData}
                propsValue={ViewValueForProject}
              />
            ) : (
              ""
            )}
            {continueChange === "createStepHome" ? (
              <HomeEssentialsSideNav
                formData={homeEsstentialformData}
                updateFormData={updateFormData}
              />
            ) : (
              ""
            )}
            {continueChange === "createStepProject" ? (
              <ProjectsSideNavNew handleFormData={updatedProjectData} />
            ) : (
              ""
            )}
            {continueChange === "createStepRemind" ? (
              <ReminderSideNav
                buttonShowingBackWith={buttonShowingBackWith}
                backButton={backButton}
                suggestedReminderAdd={suggestedReminderAdd}
                handlerCustomReminder={handlerCustomReminder}
              />
            ) : (
              ""
            )}
            {continueChange === "createStepCustomReminder" ? (
              <ReminderSideNavCreate
                suggestedReminderAdd={suggestedReminderAdd}
                buttonShowingBackWith={buttonShowingBackWith}
                backButton={backButton}
              />
            ) : (
              ""
            )}
            {continueChange === "createStepPServices" ? (
              <PurchasesServicesNavAdd
                handleCheckboxChangeOLD={handleCheckboxChangeOLD}
                updatedServiceData={updatedServiceData}
                updatePurchaseData={updatePurchaseData}
                openAddProductModal={openAddProductModal}
                purchaseLinItem={prodLineItem}
                serviceLineItem={servLineItem}
                removeLineItemFunc={removeLineItemFunc}
                setUpdateProItem={setUpdateProItem}
                setSelectedTab={setSelectedTab}
                quickAddDetail={quickAddDetail}
              />
            ) : (
              ""
            )}
            {continueChange === "createStepFiles" ? (
              <FilesSideNavAdd handlerCustomReminder={handlerCustomFile} />
            ) : (
              ""
            )}
            {continueChange === "viewStepHome" ? (
              <HomeEssentialsSideNavView propsValue={homeEssentialViewId} />
            ) : (
              ""
            )}
            {continueChange === "EditStepHome" ? (
              <HomeEssentialsSideNavEdit
                updatedFormData={updatedFormData}
                homeEssentialId={homeEssentialViewId}
              />
            ) : (
              ""
            )}

            <div className="side-footer">
              <button
                className="footer-button-cancel"
                onClick={handlerCancelSideNav}
              >
                Cancel
              </button>
              <button
                className="footer-button-primary "
                onClick={handlerChangeStep}
              >
                {continueChange.includes("Edit")
                  ? "Save"
                  : continueChange.includes("create")
                  ? "Save"
                  : "Edit"}
              </button>

              {continueChange.includes("Edit") ? (
                <button className="footer-button-delete footer-button-back">
                  Delete
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
      <AddlineItem
        show={openModaLineItem}
        onClose={closeAddProdModal}
        prodLineItem={prodLineItem}
        setProductLineItems={setProductLineItems}
        setUpdateProItem={setUpdateProItem}
        servLineItem={servLineItem}
        setServiceLineItems={setServiceLineItems}
        updateProdItem={updateProdItem}
        selectedTab={selectedTab}
      />
      <QuickAction
        sidebarInfoFunc={(activeItem, detail) => {
          if (activeItem) {
            switch (activeItem) {
              case "HOME_ESSENTIAL":
                handlerHomeEssentialSideNave();
                break;
              case "REMINDERS":
                handlerRemindersSideNave();
                break;
              case "PROJECTS":
                handlerProjectsSideNave();
                break;
              case "FILES":
                handlerFilesSideNave();
                break;
              default:
                handlerPServicesSideNave(activeItem, detail);
                break;
            }
          }
        }}
      />
    </>
  );
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import DataTable, { Alignment, Direction } from "react-data-table-component";
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg';
import refetchIcon from '../../asstes/dash-icons/refetchIcon.svg'; // Assuming you have this icon
import { Modal, Button } from 'react-bootstrap'; // Modal for showing details
import { getHomeEsstentialReminders } from '../../../axiosCalls/userdashboard';

const LinkedReminders = ({ reminders }) => {
    const [loading, setLoading] = useState(false);    // Loading state
    const [selectedReminder, setSelectedReminder] = useState(null);  // To store the clicked reminder for popup
    const [showModal, setShowModal] = useState(false);  // Modal visibility state


    // Function to show reminder details in a modal
    const handleViewDetails = (reminder) => {
        setSelectedReminder(reminder);  // Set the selected reminder for modal
        setShowModal(true);  // Show the modal
    };



    // Define the columns for the table
    const columns = [
        {
            name: "Reminder Title",
            selector: (row) => row.reminderTitle,
            sortable: false,
            reorder: true,
        },
        {
            name: "Frequency",
            selector: (row) => row.frequency,
            sortable: false,
            reorder: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },
    ];


    // Map the API response to the required format
    const data = reminders.map((reminder) => ({

        id: reminder.id,
        reminderTitle: <div className='REminderTitle'>{reminder.title}</div>,
        frequency: <div className='Frequency'>{reminder.frequency}</div>,
        action: (
            <div className='actions'>
                {/* Eye icon for viewing details */}
                <span onClick={() => handleViewDetails(reminder)}>
                    <img src={eyeIcon} alt="View" />
                </span>

            </div>
        ),
    }));

    return (
        <>


            {/* DataTable */}
            <div className="linkReminderTable">
                <DataTable
                    columns={columns}
                    data={data}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={false}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={"300px"}
                    progressPending={loading}  // Show a loading state while fetching data
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                />
            </div>

            {/* Modal for viewing reminder details */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reminder Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedReminder && (
                        <>
                            <p><strong>Title:</strong> {selectedReminder.title}</p>
                            <p><strong>Description:</strong> {selectedReminder.description}</p>
                            <p><strong>Frequency:</strong> {selectedReminder.frequency}</p>
                            <p><strong>Date:</strong> {selectedReminder.date}</p>
                            <p><strong>Time:</strong> {selectedReminder.time}</p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LinkedReminders;

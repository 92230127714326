import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { getStates } from '../../axiosCalls/userdashboard';
import { addDataToGiftRemind, fetchWalletBalance } from '../../axiosCalls/seller';
import {  PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify'; // Import toast for notifications

const GiftNowPopup = (props) => {
    const [giftSubscriptionPopup, setGiftSubscriptionPopup] = useState(props.step);
    const [selectedOption, setSelectedOption] = useState('card');
    const [states, setStates] = useState([]);
    const [balance, setBalance] = useState(0);
    const stripe = useStripe();
    const elements = useElements();

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        street: '',
        state: '',
        zip_code: '',
        stripe_transaction_id: '',
    });

    useEffect(() => {
        const walletBalance = async () => {
            const res = await fetchWalletBalance();
            setBalance(res.data.balance);
        };
        walletBalance();
    }, []);

    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await getStates();
                setStates(response.data);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };
        fetchStates();
    }, []);

    useEffect(() => {
        if (selectedOption === 'card') {
        }
    }, [selectedOption]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handlerNextStep = (step) => {
        if (validateForm()) {
            setGiftSubscriptionPopup(step);
        }
    };

    const handlerClosePopup = () => {
        props.handlerClosePopup(false);
        setGiftSubscriptionPopup('first');
    };

    const handlerBackPopup = () => {
        setGiftSubscriptionPopup('first');
    };

    const validateForm = () => {
        const { first_name, last_name, email, phone, address, state, zip_code } = formData;
        if (!first_name || !last_name || !email || !phone || !address || !state || !zip_code) {
            toast.error('Please fill in all required fields!');
            return false;
        }
        return true;
    };

    const isCheckoutDisabled = () => {
        return selectedOption === 'wallet' && balance < 30;
    };



    const handleCheckout = async (e) => {
        e.preventDefault();

        if (isCheckoutDisabled()) {
            toast.error("Your wallet amount is less than your required amount");
            return;
        }

        if (!stripe || !elements) {
            console.error('Stripe has not loaded.');
            return;
        }

        // Ensure PaymentElement is mounted
        const paymentElement = elements.getElement(PaymentElement);
        if (!paymentElement) {
            console.error('PaymentElement is not mounted.');
            return;
        }

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href, // Optional: Redirect after payment
            },
            redirect: 'if_required',
        });

        if (error) {
            toast.error(error.message);
            console.log(error);
        } else if (paymentIntent) {
            console.log('Payment successful! Transaction ID:', paymentIntent.id);
            setFormData((prev) => ({
                ...prev,
                stripe_transaction_id: paymentIntent.id,
            }));

            handlerSubmitDataToApis();

            // Handle successful payment

        }
    };
    const handlerSubmitDataToApis = async () => {

        const response  = await addDataToGiftRemind(formData);
        if(response.data){
            toast.success(response.data.message);
            handlerClosePopup();
        }

    };
    return (
        <>
            {giftSubscriptionPopup === 'first' ? (
                <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className="GiftSubPopup">
                    <Modal.Header closeButton>
                        <h3 className="gift-popup-center-heading">Gift Remind</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Homeowner First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleInputChange}
                                        placeholder="Mockk"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Homeowner Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleInputChange}
                                        placeholder="Freddy"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Homeowner Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="mockkfreddy@gmail.com"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Homeowner Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        placeholder="XXX-XXX-XXX"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Homeowner Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        placeholder="Please enter your address here"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Label>Street</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        value={formData.street}
                                        onChange={handleInputChange}
                                        placeholder="Street #123"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select name="state" value={formData.state} onChange={handleInputChange}>
                                        <option value="">Select a state</option>
                                        {states.map((state) => (
                                            <option key={state.abbreviation} value={state.abbreviation}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <Form.Label>ZIP Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zip_code"
                                        value={formData.zip_code}
                                        onChange={handleInputChange}
                                        placeholder="32894"
                                    />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="giftPopup-modal-footer-btns">
                            <button className="footer-button-cancel" onClick={handlerClosePopup}>Cancel</button>
                            <button className="proccedd-btn" onClick={() => handlerNextStep('second')}>Proceed</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            ) : (



                <Modal {...props} >
                    <Modal.Header closeButton>
                        <h3 className="gift-popup-center-heading">Payment</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <h2 className="payVia">Pay Via:</h2>
                        <div className="d-flex giftpopup-radio-swtich">
                            <div className="radio-role-selection">
                                <input
                                    type="radio"
                                    id="card"
                                    checked={selectedOption === 'card'}
                                    onChange={handleOptionChange}
                                    value="card"
                                    className="role-radio-btn"
                                    name="role_choose"
                                />
                                <label htmlFor="card" className="giftpopup-radio-label">Card</label>
                            </div>
                            <div className="radio-role-selection">
                                <input
                                    type="radio"
                                    id="wallet"
                                    checked={selectedOption === 'wallet'}
                                    onChange={handleOptionChange}
                                    value="wallet"
                                    className="role-radio-btn"
                                    name="role_choose"
                                />
                                <label htmlFor="wallet" className="giftpopup-radio-label">REmind Wallet</label>
                            </div>
                        </div>
                        <Form className='checkout-form' onSubmit={handleCheckout}>



                            {selectedOption === 'card' ? (

                                <PaymentElement />

                            ) : (
                                <div className="giftpopuo-wallet">
                                    <div className="giftpopuo-wallet-label-AccountBal">Account Balance</div>
                                    <div className="giftpopuo-wallet-AccountBal">${balance}</div>
                                    <div className="giftpopuo-wallet-description">Gift charge $30 only from your wallet</div>
                                </div>
                            )}

                            <Modal.Footer>
                                <div className="giftPopup-modal-footer-btns">
                                    <button className="footer-button-cancel" onClick={handlerBackPopup}>Back</button>
                                    <button className="footer-button-cancel" onClick={handlerClosePopup}>Cancel</button>
                                    <button
                                        type="submit"
                                        className="proccedd-btn"
                                        disabled={!stripe || isCheckoutDisabled()}
                                    >
                                        Checkout
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>
                </Modal>

            )}
        </>
    );
};

export default GiftNowPopup;

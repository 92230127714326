import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const userStatus = user?.meta_data?.status;
  const userRole = user?.role;
  const trialDate = new Date(user?.meta_data?.trial_end_date);
  const currentDate = new Date();
  const isTrailEnd = currentDate > trialDate;
  if (!token) {
    // Not authenticated
    return <Navigate to="/login" />;
  }
  if (userStatus === "inactive" || (isTrailEnd && userRole === "homeowner")) {
    // Redirect to user settings page if the user is inactive
    if (user.role === "affiliate") {
      return <Navigate to="/seller/seller-user-setting" />;
    } else {
      return <Navigate to="/user-setting" />;
    }
  }

  // If role is 'affiliate' and the user is trying to access the root domain or dashboard,
  // redirect to '/seller/overview'
  if (
    user.role === "affiliate" &&
    (location.pathname === "/" || location.pathname === "/dashboard")
  ) {
    return <Navigate to="/seller/overview" />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    // Authenticated but not authorized
    return <Navigate to="/unauthorized" />;
  }
  return children;
};

export default ProtectedRoute;

import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function AddlineItem({
  show,
  onClose,
  prodLineItem,
  setProductLineItems,
  servLineItem,
  setServiceLineItems,
  setUpdateProItem,
  updateProdItem,
  selectedTab = "purchase" | "services" | "service",
}) {
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const addProdFunc = () => {
    if (!productName || !productPrice) {
      toast.error("All fields are required.");
      return false;
    }
    if (selectedTab === "purchase") {
      const cloneProductItem = [...prodLineItem];
      if (updateProdItem) {
        cloneProductItem[updateProdItem.index].name = productName;
        cloneProductItem[updateProdItem.index].price = productPrice;
      }
      if (!updateProdItem)
        cloneProductItem.push({
          name: productName,
          price: productPrice,
        });
      setProductLineItems(cloneProductItem);
    }
    if (selectedTab === "services" || selectedTab === "service") {
      const cloneServiceItem = [...servLineItem];
      if (updateProdItem) {
        cloneServiceItem[updateProdItem.index].name = productName;
        cloneServiceItem[updateProdItem.index].price = productPrice;
      }
      if (!updateProdItem)
        cloneServiceItem.push({
          name: productName,
          price: productPrice,
        });
      setServiceLineItems(cloneServiceItem);
    }
    handleClose();
  };
  const handleClose = () => {
    setProductPrice("");
    setProductName("");
    setUpdateProItem(null);
    onClose();
  };
  useEffect(() => {
    setProductName(updateProdItem?.name || "");
    setProductPrice(updateProdItem?.price || "");
  }, [updateProdItem]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form.Label>
          Item Name <span className="requiredStar">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Please Enter Item Name"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
        />
        <Form.Label className="mt-3">
          Item Price <span className="requiredStar">*</span>
        </Form.Label>
        <Form.Control
          type="number"
          placeholder="Please Enter Item Price"
          value={productPrice}
          onChange={(e) => setProductPrice(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="logout-row">
          <div className="logout-button" onClick={handleClose}>
            Cancel
          </div>
        </div>
        <div className="reminder-top-button">
          <button className="filled-btn" onClick={addProdFunc}>
            {updateProdItem ? "Update" : "Add"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Select } from "./Select";
import {
  fetchHomeEssentials,
  get_event_by_id,
  listOfFrequencies,
} from "../../../axiosCalls/userdashboard";

const ReminderSideNavEdit = ({ reminder, suggestedReminderAdd }) => {
  const [selectedReminder, setSelectedReminder] = useState({}); // Initialize as an empty object
  const [setEeminderID, setSelectedreminder] = useState(reminder); // Initialize as an empty object
  const [frequencies, setFrequencies] = useState([]);
  const [selectedHomeEssentials, setSelectedHomeEssentials] = useState([]);
  const [homeEssentials, setHomeEssentials] = useState([]); // State for home essentials

  // Fetching frequencies data
  useEffect(() => {
    const fetchFrequencies = async () => {
      try {
        const response = await listOfFrequencies();
        setFrequencies(response.data);
      } catch (error) {
        console.error("Error fetching frequencies:", error);
      }
    };
    fetchFrequencies();
  }, []);

  // Fetching home essentials data
  useEffect(() => {
    const fetchEssentials = async () => {
      try {
        const response = await fetchHomeEssentials(); // Assuming this function returns the home essentials data
        const optionsData = response.data.items.map((item) => ({
          label: `${item.type} - ${item.model_number}`, // Display type and model number as label
          value: item.id, // ID as value
        }));
        setHomeEssentials(optionsData);
      } catch (error) {
        console.error("Error fetching home essentials:", error);
      }
    };
    fetchEssentials();
  }, []);

  useEffect(() => {
    suggestedReminderAdd(selectedReminder);
  }, [selectedReminder, suggestedReminderAdd]); // Ensure that suggestedReminderAdd is updated when selectedReminder changes

  // Fetch reminder data when reminder changes
  useEffect(() => {
    const fetchReminder = async () => {
      if (reminder) {
        try {
          const res = await get_event_by_id(reminder);
          setSelectedReminder(res.data); // Set fetched reminder data to state
          if (res.data.linked_home_essentials)
            setSelectedHomeEssentials(res.data.linked_home_essentials);
        } catch (error) {
          console.error("Error fetching reminder by ID:", error);
        }
      }
    };

    fetchReminder();
  }, [reminder]); // Only fetch when reminder changes

  // Handle frequency change
  const handleFrequencyChange = (e) => {
    const selectedFrequency = e.target.value;
    const frequencyData = frequencies.find(
      (freq) => freq.name === selectedFrequency
    );
    if (frequencyData) {
      setSelectedReminder((prevState) => ({
        ...prevState,
        frequency: frequencyData.name,
        reminder_frequency: frequencyData.reminder_frequency,
        time: frequencyData.time,
        frequency_id: frequencyData.id,
      }));
    }
  };

  // Handle home essential selection change
  const handleHomeEssentialChange = (selected) => {
    setSelectedHomeEssentials(selected);

    // Update the selected reminder with linked home essentials whenever this changes
    setSelectedReminder((prevState) => ({
      ...prevState,
      linked_home_essentials: selected,
    }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="side-header">
        <h3>Edit Reminder</h3>
      </div>
      <div className="side-body CreateReminderSideBody mt-3">
        <Form>
          <Row>
            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>
                Title <span className="requiredStar">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                value={selectedReminder.title || ""} // Controlled input
                onChange={(e) =>
                  setSelectedReminder({
                    ...selectedReminder,
                    title: e.target.value,
                  })
                }
                required
              />
            </Form.Group>

            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={selectedReminder.description || ""} // Controlled input
                onChange={(e) =>
                  setSelectedReminder({
                    ...selectedReminder,
                    description: e.target.value,
                  })
                }
                required
              />
            </Form.Group>

            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Label>
                Frequency <span className="requiredStar">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={selectedReminder.frequency || ""} // Controlled select
                onChange={handleFrequencyChange}
                required
              >
                <option value="" disabled>
                  Select frequency
                </option>
                {frequencies.map((freq) => (
                  <option key={freq.id} value={freq.name}>
                    {freq.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Label>
                Reminder Start Date <span className="requiredStar">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                value={selectedReminder.date || ""} // Controlled input
                onChange={(e) =>
                  setSelectedReminder({
                    ...selectedReminder,
                    date: e.target.value,
                  })
                }
                required
              />
            </Form.Group>

            <Form.Group as={Col} md="4" className="mb-3">
              <Form.Label>Time</Form.Label>
              <Form.Control
                type="time"
                value={selectedReminder.time || ""} // Controlled input
                onChange={(e) =>
                  setSelectedReminder({
                    ...selectedReminder,
                    time: e.target.value,
                  })
                }
                required
              />
            </Form.Group>

            {/* Linked Home Essential */}
            <Form.Group as={Col} md="12" className="mb-3">
              <Form.Label>Linked Home Essential</Form.Label>
              <Select
                multiple
                options={homeEssentials} // Updated options to use home essentials from API
                value={selectedHomeEssentials}
                onChange={handleHomeEssentialChange} // Use the dedicated handler
              />
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ReminderSideNavEdit;

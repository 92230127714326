import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ListOfFIlesForEssemtialNav from './ListOfFIlesForEssemtialNav';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import BroomIcon from '../../asstes/dash-icons/sidebar/Broom.svg';
import { getPurchaseItem, getReminderById, getServiceItem } from '../../../axiosCalls/userdashboard';
import LinkedReminders from './linkedReminders';

const PurchasesServiceSideView = ({ selectedId, itemType }) => {
    const [purchaseData, setPurchaseData] = useState(null); // State for storing the fetched purchase data
    const [serviceData, setServiceData] = useState(null); // State for storing the service data
    const [selectedID, setSelectedID] = useState(selectedId); // Store the selected ID
    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);
    const [itemTpe, setItemType] = useState(itemType); // Store the selected ID
    const [reminders, setReminders] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (itemType === 'purchase') {
                    const res = await getPurchaseItem(selectedId);
                    setPurchaseData(res.data); // Set the purchase data
                    setServiceData(null); // Clear service data
                } else if (itemType === 'service') {
                    const resservice = await getServiceItem(selectedId);
                    setServiceData(resservice.data); // Set the service data
                    setPurchaseData(null); // Clear purchase data
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [selectedId, itemType]);

    // Effect for fetching reminders when homeEssential is available
    useEffect(() => {
        const fetchReminderByID = async () => {
            try {
                if (serviceData && serviceData.linked_reminders && serviceData.linked_reminders.length > 0) {
                    // Fetch all reminders based on IDs
                     const reminderPromises = serviceData.linked_reminders.map(async (item) => {
                            item = JSON.parse(item);
                        const response = await getReminderById(item.id); // Assuming item has an id property
                        return response && response.data ? response.data : null;
                    });
                    // Wait for all promises to resolve
                    const results = await Promise.all(reminderPromises);
                    // Filter out any null responses and set state
                    const filteredResults = results.filter(result => result !== null);
                    setReminders(filteredResults);
                 }
            } catch (error) {
                console.error("Error fetching reminder data:", error);
            }
        };

        if (serviceData) {
            fetchReminderByID();
        }
    }, [serviceData]); // This useEffect depends on homeEssential


    // Effect for fetching reminders when homeEssential is available
    useEffect(() => {
        const fetchReminderByID = async () => {
            try {
                if (purchaseData && purchaseData.linked_reminders && purchaseData.linked_reminders.length > 0) {
                    // Fetch all reminders based on IDs
                      const reminderPromises = purchaseData.linked_reminders.map(async (item) => {
                            item = JSON.parse(item);
                        const response = await getReminderById(item.id); // Assuming item has an id property
                        return response && response.data ? response.data : null;
                    });

                    // Wait for all promises to resolve
                    const results = await Promise.all(reminderPromises);

                    // Filter out any null responses and set state
                    const filteredResults = results.filter(result => result !== null);
                    setReminders(filteredResults);
                 }
            } catch (error) {
                console.error("Error fetching reminder data:", error);
            }
        };

        if (purchaseData) {
            fetchReminderByID();
        }
    }, [purchaseData]); // This useEffect depends on homeEssential


    // Function to handle file viewing
    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };

    // Function to handle file download
    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop()); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Remove the link after download
    };

    // Rendering logic based on itemType (purchase or service)
    return (
        <div className='side-nav'>
            <div className='side-header'>
                <h3>{itemType === 'purchase' ? 'Purchase Details' : 'Service Details'}</h3>
            </div>
            <div className='side-body homeEssentialSideNav'>
                <Form>
                    <Row style={{ padding: '0px' }}>
                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <div className='d-flex purchase-view-name'>
                                    <Form.Label>{itemType === 'purchase' ? 'Product Name' : 'Service Name'}</Form.Label>
                                    <span className={itemType === 'purchase' ? `services-badge type-green` : `services-badge type-camel`}>
                                        <img src={BroomIcon} alt="icon" />
                                        {itemType === 'purchase' ? 'Purchase' : 'Service'}
                                    </span>
                                </div>
                                <p className='text-paragraph'>
                                    {itemType === 'purchase' ? purchaseData?.product_name : serviceData?.service_name}
                                </p>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Notes</Form.Label>
                                <p className='text-paragraph'>
                                    {itemType === 'purchase' ? purchaseData?.notes : serviceData?.notes}
                                </p>
                            </div>
                        </Form.Group>

                        {itemType === 'purchase' && (
                            <>
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <div className='link-table-main'>
                                        <Form.Label>Make</Form.Label>
                                        <p className='text-paragraph'>{purchaseData?.make}</p>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-3">
                                    <div className='link-table-main'>
                                        <Form.Label>Model Number</Form.Label>
                                        <p className='text-paragraph'>{purchaseData?.model_number}</p>
                                    </div>
                                </Form.Group>
                            </>
                        )}

                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Date</Form.Label>
                                <p className='text-paragraph'>
                                    {itemType === 'purchase' ? purchaseData?.purchase_date : serviceData?.service_date}
                                </p>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Amount</Form.Label>
                                <p className='text-paragraph'>
                                    ${itemType === 'purchase' ? purchaseData?.amount : serviceData?.amount}
                                </p>
                            </div>
                        </Form.Group>
                           {/* Linked Reminders */}
                           <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Linked Reminders</Form.Label>
                                <LinkedReminders reminders={reminders} />

                            </div>
                        </Form.Group>
                        {serviceData?.receipt_url?.length > 0 && (
                            <Form.Group md="12" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Receipt Files</Form.Label>
                                    <div className='listofFiles'>
                                        {serviceData.receipt_url.map((file, index) => (
                                            <div className='warrantyFile' key={index}>
                                                <div className='text'>
                                                    <img src={pdfIcon} alt='pdfIcon' />
                                                    {file.split('/').pop()}
                                                </div>
                                                <div className='actions'>
                                                    <span onClick={() => handleViewFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={eyeIcon} alt='View' />
                                                    </span>
                                                    <span onClick={() => handleDownloadFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={downloadIcon} alt='Download' />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                        )}
                        {serviceData?.uploaded_files?.length > 0 && (
                            <Form.Group md="12" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Files</Form.Label>
                                    <div className='listofFiles'>
                                        {serviceData.uploaded_files.map((file, index) => (
                                            <div className='warrantyFile' key={index}>
                                                <div className='text'>
                                                    <img src={pdfIcon} alt='pdfIcon' />
                                                    {file.split('/').pop()}
                                                </div>
                                                <div className='actions'>
                                                    <span onClick={() => handleViewFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={eyeIcon} alt='View' />
                                                    </span>
                                                    <span onClick={() => handleDownloadFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={downloadIcon} alt='Download' />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                        )}


                        {purchaseData?.receipt_url?.length > 0 && (
                            <Form.Group md="12" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Receipt Files</Form.Label>
                                    <div className='listofFiles'>
                                        {purchaseData.receipt_url.map((file, index) => (
                                            <div className='warrantyFile' key={index}>
                                                <div className='text'>
                                                    <img src={pdfIcon} alt='pdfIcon' />
                                                    {file.split('/').pop()}
                                                </div>
                                                <div className='actions'>
                                                    <span onClick={() => handleViewFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={eyeIcon} alt='View' />
                                                    </span>
                                                    <span onClick={() => handleDownloadFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={downloadIcon} alt='Download' />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                        )}
                        {purchaseData?.uploaded_files?.length > 0 && (
                            <Form.Group md="12" className="mb-3">
                                <div className='link-table-main'>
                                    <Form.Label>Files</Form.Label>
                                    <div className='listofFiles'>
                                        {purchaseData.uploaded_files.map((file, index) => (
                                            <div className='warrantyFile' key={index}>
                                                <div className='text'>
                                                    <img src={pdfIcon} alt='pdfIcon' />
                                                    {file.split('/').pop()}
                                                </div>
                                                <div className='actions'>
                                                    <span onClick={() => handleViewFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={eyeIcon} alt='View' />
                                                    </span>
                                                    <span onClick={() => handleDownloadFile(file)} style={{ cursor: 'pointer' }}>
                                                        <img src={downloadIcon} alt='Download' />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                        )}

                        <Form.Group as={Col} md="12" className="mb-3">
                            <div className='link-table-main'>
                                <Form.Label>Warranty</Form.Label>
                                <div className='warranty-section'>
                                    <span><b>Name: </b>{itemType === 'purchase' ? purchaseData?.warranty_data['0']?.name : serviceData?.warranty_data['0']?.name}</span>
                                    <span><b>Number: </b>{itemType === 'purchase' ? purchaseData?.warranty_data['1']?.number : serviceData?.warranty_data['1']?.number}</span>
                                    <span><b>Dates: </b>{itemType === 'purchase' ? `${purchaseData?.warranty_data['2']?.start_date} - ${purchaseData?.warranty_data['3']?.end_date}` : `${serviceData?.warranty_data['2']?.start_date} - ${serviceData?.warranty_data['3']?.end_date}`}</span>
                                    <span><b>Notes: </b>{itemType === 'purchase' ? purchaseData?.warranty_data['4']?.notes : serviceData?.warranty_data['4']?.notes}</span>

                                    {/* Warranty Files */}
                                    {serviceData?.warranty_data?.files?.length > 0 && (
                                        <Form.Group md="12" className="mb-3">
                                            <div className='link-table-main'>
                                                <Form.Label>Warranty Files</Form.Label>
                                                <div className='listofFiles'>
                                                    {serviceData.warranty_data.files.map((file, index) => (
                                                        <div className='warrantyFile' key={index}>
                                                            <div className='text'>
                                                                <img src={pdfIcon} alt='pdfIcon' />
                                                                {file.split('/').pop()}
                                                            </div>
                                                            <div className='actions'>
                                                                <span onClick={() => handleViewFile(file)} style={{ cursor: 'pointer' }}>
                                                                    <img src={eyeIcon} alt='View' />
                                                                </span>
                                                                <span onClick={() => handleDownloadFile(file)} style={{ cursor: 'pointer' }}>
                                                                    <img src={downloadIcon} alt='Download' />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    )}
                                    {/* Warranty Files */}
                                    {purchaseData?.warranty_data?.files?.length > 0 && (
                                        <Form.Group md="12" className="mb-3">
                                            <div className='link-table-main'>
                                                <Form.Label>Warranty Files</Form.Label>
                                                <div className='listofFiles'>
                                                    {purchaseData.warranty_data.files.map((file, index) => (
                                                        <div className='warrantyFile' key={index}>
                                                            <div className='text'>
                                                                <img src={pdfIcon} alt='pdfIcon' />
                                                                {file.split('/').pop()}
                                                            </div>
                                                            <div className='actions'>
                                                                <span onClick={() => handleViewFile(file)} style={{ cursor: 'pointer' }}>
                                                                    <img src={eyeIcon} alt='View' />
                                                                </span>
                                                                <span onClick={() => handleDownloadFile(file)} style={{ cursor: 'pointer' }}>
                                                                    <img src={downloadIcon} alt='Download' />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Form.Group>
                                    )}

                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                </Form>

                {/* Modal to View File */}
                <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                        {fileToView && fileToView.endsWith('.pdf') ? (
                            <iframe
                                src={fileToView}
                                style={{ width: '100%', height: '500px' }}
                                frameBorder="0"
                                title="PDF Viewer"
                            />
                        ) : (
                            <img src={fileToView} alt="File" style={{ width: '100%' }} />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default PurchasesServiceSideView;

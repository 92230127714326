import { toast } from "react-toastify";

const isPropertyExist = () => {
  const propertyData = JSON.parse(localStorage.getItem("selectedProperty"));
  if (!propertyData) {
    toast.error("Please Add Property First");
    return false;
  }
  return true;
};
const isPlanActive = () => {
  const userDetail = JSON.parse(localStorage.getItem("user"));
  if (userDetail?.is_plan_active) {
    return true;
  }
  return false;
};
export { isPropertyExist, isPlanActive };

import React, { useEffect, useState } from 'react';
import DataTable, { Alignment, Direction } from "react-data-table-component";
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg'
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg'
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg'
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg'
import FilesSideNavAdd from './filesSideNavAdd';
import FilesSideNavEdit from './filesSideNavEdit';
import FilesSideNavView from './filesSideNavView';
import { getFilesList, updateFileApis } from '../../../axiosCalls/userdashboard';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const RecentFiles = () => {

    const [tabSelection, setTabSelection] = useState('table');
    const [continueChange, setContinueChange] = useState('ViewFileStep');
    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [fileList, setFileList] = useState([]);
    const [selectValue, setSelectValue] = useState([]);
    const [NewFileData, setNewFileData] = useState([]);

    useEffect(() => {
        const getListOfFile = async () => {
            try {
                const res = await getFilesList();
                const data = Array.isArray(res.data) ? res.data : [];
                setFileList(data);
            } catch (error) {
                console.error("Error fetching files:", error);
                setFileList([]); // Set an empty list on error to avoid further issues
            }
        };
        getListOfFile();
    }, []);

    const columns = [
        {
            name: "File name",
            selector: (row) => row.filename,
            sortable: false,
            reorder: true,
        },
        {
            name: "",
            selector: (row) => row.action,
            sortable: false,
            reorder: true,
        },

    ];
    // Helper function to get file icon based on file type
    const getFileIcon = (file) => {
        if (file instanceof File) {
            if (file.type === 'application/pdf') {
                return pdfIcon;
            } else {
                return URL.createObjectURL(file); // Get object URL for image files
            }
        } else {
            const fileExtension = file.split('.').pop().toLowerCase();
            return fileExtension === 'pdf' ? pdfIcon : file;
        }
    };
    // Function to handle file viewing
    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };
    // Function to handle file download
    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop()); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Remove the link after download
    };

    const dataFiles = fileList
    .filter((_, index) => index <= 5)
    .flatMap((item, itemIndex) => {
        // Ensure that item.files is a valid array, otherwise use an empty array
        const files = Array.isArray(item.files) ? item.files : [];

        if (files.length === 0) {
            // Handle cases where files are empty or not available
            return [{
                id: `${itemIndex + 1}-no-files`,
                filename: (
                    <div className='fileName'>
                        <span>No files available</span> {/* Show placeholder if no files */}
                    </div>
                ),
                action: <div className='actions'>N/A</div>, // No actions available
                Type: <span className="services-badge type-red">No Files</span>,
                date: item.upload_date, // Still show the upload date if available
                data: item
            }];
        }

        // If files are available, map over them
        return files.map((fileUrl, fileIndex) => {
            const fileName = fileUrl.split('/').pop(); // Extract file name from URL

            return {
                id: `${itemIndex + 1}-${fileIndex + 1}`, // Unique ID using indices
                filename: (
                    <div className='fileName'>
                        <img src={getFileIcon(fileUrl)} alt="file icon" className='fileItemImage' />
                        {fileName}
                    </div>
                ),
                action: (
                    <div className='actions'>
                        <span><img src={eyeIcon} alt="view icon" onClick={() => handleViewFile(fileUrl)} /></span>
                        <span><img src={downloadIcon} alt="download icon" onClick={() => handleDownloadFile(fileUrl)} /></span>
                    </div>
                ),
                Type: <span className="services-badge type-green">{item.file_name}</span>,
                date: item.upload_date, // Display the upload date if available
                data: item
            };
        });
    });

    const handlerOpenViewFile = (e) => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('ViewFileStep');
        document.body.classList.add('no-scroll');
        setSelectValue(e.data)

    }
    const handlerNewSideNave = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        setContinueChange('createStep');
        document.body.classList.add('no-scroll');


    }
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');


    }
    const handlerChangeForStep = async() => {
        if (continueChange === 'ViewFileStep') {
            setContinueChange('EditFileStep');
        }
        if(continueChange === 'EditFileStep'){
            try {

                let data = new FormData();
                data.append('file_name', NewFileData.fileName);
                data.append('upload_date', NewFileData.uploadDate);

                //  data.append('related_to', '3');
                if (NewFileData.files) {
                    NewFileData.files.forEach(file => data.append('files[]', file));
                }
                if (NewFileData.reminderIds) {
                    NewFileData.reminderIds.forEach(remind => data.append('reminder_ids[]', remind));
                }
                const res = await updateFileApis(data, selectValue.post_id)
                if (res && res.status) {
                    toast.success(res.data.message)
                    handlerCancelSideNav()
                }
            } catch (error) {
                console.log("ERROR::", error)
            }
        }
     }

    const upHandlerCustomReminder = (data) => {

        setNewFileData(data)
    }
    const handlerCustomReminder = (data) => {

        setNewFileData(data)
    }

    return (
        <div className="recentFilesTable mt-3 dashboard-recent">
            <DataTable
                columns={columns}
                data={dataFiles}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={false}
                highlightOnHover={true}
                onRowClicked={handlerOpenViewFile}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={"300px"}
                progressPending={false}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />


            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>

                {continueChange === 'createStep' ? '' : continueChange === 'ViewFileStep' ? <FilesSideNavView selectValue={selectValue} /> : <FilesSideNavEdit selectValue={selectValue} upHandlerCustomReminder={upHandlerCustomReminder} />}


                <div className='side-footer'>

                    <button className='footer-button-cancel' onClick={handlerCancelSideNav}>Cancel</button>


                    <button className='footer-button-primary ' onClick={handlerChangeForStep}>  {continueChange === 'createStep' ? "Save" : continueChange === 'ViewFileStep' ? "Edit" : "Save"}</button>
                    {continueChange === 'createStep' || continueChange === 'ViewFileStep' ? '' : <button className='footer-button-delete footer-button-back' >Delete</button>}

                </div>

            </div>
     {/* Modal to View File */}
     <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    {fileToView && fileToView.endsWith('.pdf') ? (
                        <iframe
                            src={fileToView}
                            style={{ width: '100%', height: '500px' }}
                            frameBorder="0"
                            title="PDF Viewer"
                        />
                    ) : (
                        <img src={fileToView} alt="File" style={{ width: '100%' }} />
                    )}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>


        </div>

    );
}

export default RecentFiles;

import React, { useEffect, useState } from "react";
import ReminderSideNavEdit from "./reminderSideNavEdit";
import ReminderSideNavView from "./reminderSideNavView";
import reminderNextbtn from '../../asstes/dash-icons/reminderNextbtn.svg';
import reminderPrvebtn from '../../asstes/dash-icons/reminderPrvebtn.svg';
import { addNotificationData, delete_notification, get_event_lists, updateCustomReminder } from "../../../axiosCalls/userdashboard";
import { toast } from "react-toastify";

const DashboardCalender = () => {
  // Helper function to get the Monday of the current week
  const getMonday = (date) => {
    const day = date.getDay();
    const diff = day === 0 ? -6 : 1 - day; // Adjust if it's Sunday
    return new Date(date.setDate(date.getDate() + diff));
  };

  const [startDate, setStartDate] = useState(getMonday(new Date()));  // Start from the Monday of the current week
  const [newEvents, setEvents] = useState([]);  // API events data
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedEventId, setselectedEventId] = useState(null);
  const [reminderData, setReminderData] = useState([]);
  const [sideNavWidth, setSideNavWidth] = useState('0px');
  const [sideNavWidthOverlay, setSideNavWidthOverlay] = useState('0%');
  const [continueChange, setContinueChange] = useState('viewStep');

  const currentDate = new Date(); // Today's date

  const frequencyMappings = {
    once: 1, daily: 1, weekly: 7, fortnightly: 14, monthly: 30, quarterly: 91,
    six_months: 182, yearly: 365
  };

  const colorMappings = {
    yearly: 'purple', six_months: 'purple', daily: 'red', once: 'red',
    weekly: 'camel', fortnightly: 'camel', monthly: 'green', quarterly: 'green'
  };

  const fetchEvents = async () => {
    try {
      const res = await get_event_lists();
      const formattedEvents = res.data.flatMap(event => {
        const eventDate = new Date(`${event.date}T${event.time}`);
        const frequency = event.reminder_frequency;
        const occurrences = [];
        const interval = frequencyMappings[frequency] || 0;
        const color = colorMappings[frequency] || 'green';

        if (frequency === 'once') {
          occurrences.push({
            id: event.ID, title: event.title, description: event.description,
            type: color, date: eventDate,
          });
        } else {
          for (let i = 0; i < 14; i++) {  // Generate occurrences for two weeks only
            const nextOccurrence = new Date(eventDate);
            nextOccurrence.setDate(eventDate.getDate() + i * interval);
            occurrences.push({
              id: event.ID, title: event.title, description: event.description,
              type: color, date: nextOccurrence,
            });
          }
        }
        return occurrences;
      });

      setEvents(formattedEvents);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [startDate]);  // Refetch events when start date changes

  const generateTwoWeekDates = (start) => {
    const dates = [];
    for (let i = 0; i < 14; i++) {
      const date = new Date(start);
      date.setDate(start.getDate() + i);
      dates.push(date);
    }
    return dates;
  };

  const twoWeekDates = generateTwoWeekDates(startDate);

  const getEventsForDate = date =>
    newEvents.filter(event => event.date.toDateString() === date.toDateString());

  const nextWeek = () => {
    setStartDate(prev => {
      const nextDate = new Date(prev);
      nextDate.setDate(prev.getDate() + 14); // Move 14 days forward
      return nextDate;
    });
  };

  const prevWeek = () => {
    setStartDate(prev => {
      const prevDate = new Date(prev);
      prevDate.setDate(prev.getDate() - 14); // Move 14 days backward
      return prevDate < currentDate ? currentDate : prevDate;
    });
  };

  const handlerReminderSideNave = (event) => {
    setselectedEventId(event.id);
    setSideNavWidth('sidenavPopuWidth');
    setSideNavWidthOverlay('100%');
    setContinueChange('viewStep');
    document.body.classList.add('no-scroll');
  };

  const handlerCancelSideNav = () => {
    setSideNavWidth('sidenavPopuWidthHide');
    setSideNavWidthOverlay('0%');
    document.body.classList.remove('no-scroll');
  };

  const handlerChangeToEdit = () => setContinueChange('editStep');

  const handlerChangeStep = async () => {
    try {
      const responseCustomReminder = await updateCustomReminder(reminderData);
      const response = await addNotificationData(reminderData, responseCustomReminder.data.post_id);
      if (response.data.status === 400) {
        toast.error(response.message);
      } else {
        toast.success(response.data.message);
        fetchEvents();
        handlerCancelSideNav();
      }
    } catch (error) { console.error(error); }
  };

  const handlerDeleteCalendar = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this event?");
    if (!confirmed) return;
    try {
      const response = await delete_notification(selectedEventId);
      if (response.status === 200) {
        toast.warning(response.data.message);
        handlerCancelSideNav();
        fetchEvents();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Error deleting event");
      console.error(error);
    }
  };

  if (loading) return <div className="loading">Loading...</div>;

  return (
    <div>
      <div className="reminder-navigation">
        <h2 className="card-heading">
          <span>
            {startDate.toLocaleString('default', { month: 'short', day: 'numeric' })} -{' '}
            {new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 13)
              .toLocaleString('default', { month: 'short', day: 'numeric' })}
          </span>

          <img onClick={prevWeek} src={reminderPrvebtn} alt="Previous Week" />
          <img onClick={nextWeek} src={reminderNextbtn} alt="Next Week" />
        </h2>
      </div>

      <div className="parent-upcomingDateRow">
        <div className="row upcomingDateRow">
          {twoWeekDates.map((date, index) => (
            <div
              className={`dayBox ${date.toDateString() === currentDate.toDateString() ? 'current-day' : ''}`}
              key={index}
            >
              <div className="customDates">
                <span>{date.toLocaleString('default', { month: 'short', day: '2-digit' })}</span>
                <span className="day">{date.toLocaleString('en-us', { weekday: 'short' })}</span>
              </div>

              <div className="event-list">
                {getEventsForDate(date).map(event => (
                  <div onClick={() => handlerReminderSideNave(event)} className="parent-event-title" key={event.id}>
                    <div className={`event-calender-title type-${event.type}`}>{event.title}</div>
                    <div className={`event-calender-des type-sub-${event.type}`}>{event.description}</div>
                  </div>
                ))}
              </div>

              <div className="event_count">
                <a href="#">Events: {getEventsForDate(date).length}</a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>
      <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
        {continueChange === 'viewStep'
          ? <ReminderSideNavView eventID={selectedEventId} />
          : <ReminderSideNavEdit reminder={selectedEventId} suggestedReminderAdd={setReminderData} />}
        <div className="side-footer">
          <button className="footer-button-cancel" onClick={handlerCancelSideNav}>Cancel</button>
          {continueChange === 'viewStep'
            ? <button className="footer-button-primary" onClick={handlerChangeToEdit}>Edit</button>
            : <button onClick={handlerChangeStep} className="footer-button-primary">Save</button>}
          {continueChange === 'viewStep' &&
            <button onClick={handlerDeleteCalendar} className="footer-button-delete footer-button-back">Delete</button>}
        </div>
      </div>
    </div>
  );
};

export default DashboardCalender;
